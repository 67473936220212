import { Component, OnInit} from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../services/global.service';
import { URLService } from '../services/url.service';
import { Menu } from '../dto/Menu';

@Component({
  selector: 'confirmation-dialog-pagseguro',
  templateUrl: 'confirmation-dialog-pagseguro.html',
  standalone: true,
  imports: [MatDialogModule, MatListModule, CommonModule, MatButtonModule],
})
export class ConfirmationDialogPagseguro implements OnInit {

  districtsDeliveryFeeObjects = new Array();

  constructor(private httpClient: HttpClient, private globalService: GlobalService, private urlService: URLService,
    private dialogRef: MatDialog) {

  }

  ngOnInit(): void {

    

  }

  confirmPagseguro() {

    document.getElementById("pagseguroBtn")?.click();

    this.dialogRef.closeAll();

    document.getElementById("pagseguroSpinBtn")?.click();

  }

}
