export class Menu {

  public id: number = 0;

  public name: string = "";

  public districtsDeliveryFeeObjects: string = "";

  public customDeliveryObjects: string = "";

  public address: string = "";

  public availabilityJson: string = "";

  public opened: boolean = false;

  public bolEnabled: boolean = false;

  public paymentGateway: string = "";

  public pixKey: string = "";

  public pagSeguroEmail: string = "";

  public pagSeguroToken: string = "";

  public pagSeguroAppKey: string = "";

}
