import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CurrencyService } from '../services/currency.service';
import { GlobalService } from '../services/global.service';
import { URLService } from '../services/url.service';
import { SpinnerService } from '../services/spinner.service';
import { Title } from '@angular/platform-browser';
import { FoodSize } from '../dto/FoodSize';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {

  controller: any = "";

  hasFlavors: boolean = false;

  hasComplemens: boolean = false;

  hasIngredients: boolean = false;

  arr = window.location.href.split("/");

  flavors = 0;

  flavorsArr = new Array();
  complementsArr = new Array();
  ingredientsArr = new Array();

  _price = 0;

  _ingredient_price = 0;

  imageHeight = null;

  sizes = new Array();

  selectedSize = "";

  selectedSizePrice = 0;
  constructor(private httpClient: HttpClient, private globalService: GlobalService, private urlService: URLService,
    private currencyService: CurrencyService, private toastr: ToastrService, private spinnerService: SpinnerService,
    private elementRef: ElementRef, private titleService: Title, private snackBar: MatSnackBar) {

  }

  ngOnInit(): void {

    let that = this;

    let storename = this.urlService.getParameter(window.location.href, "loja");

    let prod_id = this.urlService.getParameter(window.location.href, "prodId");

    setInterval(function () {

      that.httpClient.get(that.globalService.url + "/" + storename + "/product-details/" + prod_id).subscribe((response) => {

        that.controller.opened = (<any>response).opened;

      });

    }, 30000)

    that.httpClient.get(that.globalService.url + "/" + storename + "/product-details/" + prod_id).subscribe((response) => {

      that.controller = response;

      that.titleService.setTitle(that.controller.storename);

      that.spinnerService.displayCoreSpinner(false, that.elementRef);

      let sizes = JSON.parse(that.controller.food.sizes);

      if (sizes != null && sizes != undefined && sizes.length > 0) {

        that.sizes = sizes;

      }

      document.getElementById("totalAmount")!.innerHTML = that.sizes.length == 0 || this.sizes.length == 1 && this.sizes[0].sizeLabel.trim() == "" ?
        that.currencyService.formatNumber(that.controller.food.price) : that.currencyService.formatNumber(that.selectedSizePrice);

    })

    let _a:any = document.getElementsByClassName("bottom-block-a");

    for (let a of _a) {

      a.style.color = this.controller.primaryColor;

    }

    let _p: any = document.getElementsByClassName("bottom-block-p");

    for (let p of _p) {

      p.style.color = this.controller.titleColor2;

    }

    this.onScroll(null);

  }

  ngAfterViewInit(): void {

    setTimeout(function () { window.scrollTo(0, 1)}, 1000)

  }

  selectSize(size: FoodSize) {

    if (this.controller.food.flavorsQuantity > 1) {

      this.ngOnInit();

      this.controller.food.price = 0;

      document.getElementById("selectedFlavors")!.innerHTML = "0";

      this.flavors = 0;

      this._price = 0;

      this._ingredient_price = 0;

      this.selectedSizePrice = 0;

    } else {

      this.selectedSizePrice = size.price;

    }

    this.selectedSize = size.sizeLabel;

    this.calcTotalAmount()

  }

  gotoMenu() {

    let orderType = localStorage.getItem("orderType");

    if (orderType == "INDOOR") {

      window.location.href = "/#/?loja=" + this.controller.storename + "&code=" + localStorage.getItem("tableCode");

    } else {

      window.location.href = "/#/?loja=" + this.controller.storename;

    }

  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {

    let that = this;
    setTimeout(function () {

      let headerText = document.getElementById("header-text");

      let _headerText = (document.getElementById("header-text")!).innerHTML;

      let image: any = document.getElementById("image");

      let rect = image.getBoundingClientRect();

      if (that.imageHeight == null) {

        that.imageHeight = <any>parseInt(image.style.height.replace("px", ""));

      }

      let top = rect.top;

      image.style.height = ((that.imageHeight) + rect.top) + "px"

      let opacity = -((that.imageHeight + rect.top) - 225) / 100
      
      if (rect.bottom <= 0) {

        document.getElementById("navbar")!.style.background = "white";

        headerText!.innerHTML = _headerText;

        document.getElementById("page-content")!.setAttribute("style", "height:100%; padding-top: 0px !important; margin-top: -25px");

        document.getElementById("delivery-categories")!.style.display = "block";

        document.getElementById("delivery-top")!.innerHTML = that.controller.title;

        (document.getElementById("delivery-top")!).style.fontWeight = "500";

        (document.getElementById("delivery-top")!).style.textTransform = "uppercase";

      } else {

        if (opacity > 0.7) {

          document.getElementById("navbar")!.style.opacity = opacity.toString();
          document.getElementById("back-icon")!.style.background = "transparent";

        } else {

          document.getElementById("navbar")!.style.opacity = "0";
          document.getElementById("back-icon")!.style.background = "white";

        }

        document.getElementById("back-icon")!.getElementsByTagName("i")[0].style.color = that.controller.primaryColor;

        document.getElementById("delivery-categories")!.style.display = "none";

        document.getElementById("delivery-top")!.style.fontWeight = "400";

        document.getElementById("delivery-top")!.style.textTransform = "unset";

        if (that.controller.opened) {

          document.getElementById("delivery-top")!.innerHTML = "Loja aberta";

        } else {

          document.getElementById("delivery-top")!.innerHTML = "Loja fechada";

        }

      }

    }, 100)

  }

  minus() {

    let quantity = (<any>document.getElementById("quantity")!).value;

    if (quantity > 1) {

      (<any>document.getElementById("quantity")!).value = parseInt(quantity) - 1;

    }

    if (quantity == 2) {

      (<any>document.getElementById("minusIcon")!).style.color = "#ccc";

    }

    this.calcTotalAmount();

  }

  plus() {

    let quantity = (<any>document.getElementById("quantity")!).value;

    (<any>document.getElementById("quantity")!).value = parseInt(quantity) + 1;

    if (quantity == 1) {

      (<any>document.getElementById("minusIcon")!).style.color = this.controller.primaryColor;

    }

    this.calcTotalAmount();

  }

  validSizes() {

    if (this.sizes != null && this.sizes != undefined && this.sizes.length > 0 && this.sizes[0].sizeLabel.trim() != "") {

      return true;

    }

    return false;

  }

  setFoodToCart(id: any) {

    if (this.sizes.length > 0 && this.selectedSize.trim() == "") {

      if (!(this.sizes.length == 1 && this.sizes[0].sizeLabel.trim() == "")) {

        //this.toastr.warning("Oops! Selecione o tamanho", '', { timeOut: 3000 });

        this.snackBar.open('Oops! Selecione o tamanho', '', {
          duration: this.globalService.snackBarDuration
        });

        return;

      }

    }

    if (!this.controller.opened) {

      document.getElementById("bottom-block-black")!.style.display = "block";
      document.getElementById("bottom-block-content")!.style.display = "block";
      document.getElementById("bottom-block-black")!.style.animationName = "opacity";
      document.getElementById("bottom-block-content")!.style.animationName = "bottom";

      return;

    }

    if (this.controller.food.flavorsQuantity > 1) {

      let _flavorsQuantity = 0;

      for (let i = 0; i < this.flavorsArr.length; i++) {

        _flavorsQuantity += this.flavorsArr[i].quantity;

      }

      if (_flavorsQuantity < this.controller.food.flavorsQuantity) {

        //this.toastr.warning("Oops! Selecione a quantidade certa de sabores", '', { timeOut: 3000 });

        this.snackBar.open('Oops! Selecione a quantidade certa de sabores', '', {
          duration: this.globalService.snackBarDuration
        });

        return;

      }

    }

    let quantity = (<any>document.getElementById("quantity")!).value;
    
    let obj: any = new Object();
    obj.id = id;
    obj.name = this.controller.food.name + (this.sizes.length > 0 && this.sizes[0].sizeLabel != "" ? " (" + this.selectedSize + ")": "");
    obj.quantity = quantity;
    obj.flavors = this.flavorsArr;
    obj.complements = this.complementsArr;
    obj.ingredients = this.ingredientsArr;
    obj.comments = (<any>document.getElementById("commentTextarea")!).value;
    
    //obj.price = this.sizes.length == 0 ? this.controller.food.price : this.selectedSizePrice;
    
    if (this.controller.food.flavorsQuantity == 1) {

      obj.price = this.sizes.length == 0 || (this.sizes.length == 1 && this.sizes[0].sizeLabel.trim() == "") ? this.controller.food.price : this.selectedSizePrice;
      
    } else {

      obj.price = this._price;
      /*
      return

      for (let i = 0; i < obj.flavors.length; i++) {

        if (this.sizes.length == 0) {

          obj.price = parseFloat(obj.price) + (parseFloat(obj.flavors[i].price) * parseInt(obj.flavors[i].quantity));

        } else {

          obj.price = this._price;

        }

      }*/

    }

    for (let i = 0; i < obj.complements.length; i++) {
      
      //obj.complements[i].unitPrice = obj.complements[i].price;
      
      obj.price = parseFloat(obj.price) + (parseFloat(obj.complements[i].price) * parseInt(obj.complements[i].quantity));
      
    }

    for (let i = 0; i < obj.ingredients.length; i++) {

      //obj.complements[i].unitPrice = obj.complements[i].price;

      obj.price = parseFloat(obj.price) + (parseFloat(obj.ingredients[i].price) * parseInt(obj.ingredients[i].quantity));
     
    }

    let _cart = localStorage.getItem("cart-" + localStorage.getItem("storename"));

    let cart: any;


    if (_cart == null || _cart == undefined || _cart.trim() == "") {

      cart = new Object();
      cart.items = new Array();

    } else {

      cart = JSON.parse(<string>localStorage.getItem("cart-" + localStorage.getItem("storename")));

    }


    if (cart.items != null && cart.items != undefined) {

      for (let j = 0; j < cart.items.length; j++) {

        if (cart.items[j].id == obj.id) {

          //cart.items.splice(j, 1);

        }

      }

    } else {

      cart = new Object();
      cart.items = new Array();

    }

    cart.items.push(obj);

    localStorage.setItem("cart-" + localStorage.getItem("storename"), JSON.stringify(cart));
    
    this.gotoMenu();

  }

  removeFlavor(i: any, flavor: any, flavorId: any) {

    let inputFlavors = parseInt((<any>document.getElementById("flavorQuantity" + i)!).value);

    if (this.flavors > 0 && inputFlavors > 0) {

      this.flavors--;

      let flavorQuantity = parseInt((<any>document.getElementById("flavorQuantity" + i)!).value) - 1;
      let flavorName = document.getElementById("flavorName" + i)!.innerHTML;

      document.getElementById("selectedFlavors")!.innerHTML = <any>this.flavors;
      (<any>document.getElementById("flavorQuantity" + i)!).value = flavorQuantity;

      let __price = 0;

      if (this.sizes.length == 0) {

        __price = parseFloat(flavor.price) / parseInt(this.controller.food.flavorsQuantity);

        this._price += __price;

      } else {

        __price = this.getFlavorPrice(flavor);

        this._price -= __price;

      }

      this.calcTotalAmount();

      let obj: any = new Object();
      obj.id = flavorId;
      obj.name = flavorName;
      obj.quantity = flavorQuantity;
      obj.price = __price

      for (let j = 0; j < this.flavorsArr.length; j++) {

        if (this.flavorsArr[j].id == obj.id) {

          this.flavorsArr.splice(j, 1);

        }

      }

      if (flavorQuantity > 0) {

        this.flavorsArr.push(obj)

      }

    }

    this.colorIcons();

  }

  addFlavor(i: any, flavor: any, flavorId: any) {

    let inputFlavors = parseInt((<any>document.getElementById("flavorQuantity" + i)!).value);
    
    if (this.flavors < this.controller.food.flavorsQuantity && this.controller.food.flavorsQuantity > inputFlavors) {

      this.flavors++;

      let flavorQuantity = parseInt((<any>document.getElementById("flavorQuantity" + i)!).value) + 1;
      let flavorName = document.getElementById("flavorName" + i)!.innerHTML;
      
      document.getElementById("selectedFlavors")!.innerHTML = this.flavors.toString();
      (<any>document.getElementById("flavorQuantity" + i)!).value = flavorQuantity;

      let __price = 0;

      if (this.sizes.length == 0) {

        __price = parseFloat(flavor.price) / parseInt(this.controller.food.flavorsQuantity);

        this._price += __price;

      } else {

        __price = this.getFlavorPrice(flavor);

        this._price += __price;

      }

      this.calcTotalAmount();

      let obj: any = new Object();
      obj.id = flavorId;
      obj.name = flavorName;
      obj.quantity = flavorQuantity;
      obj.price = __price;
      
      for (let j = 0; j < this.flavorsArr.length; j++) {

        if (this.flavorsArr[j].id == obj.id) {

          this.flavorsArr.splice(j, 1);

        }

      }

      this.flavorsArr.push(obj)

    }

    this.colorIcons();

  }

  calcTotalAmount() {

    let quantity = (<any>document.getElementById("quantity")!).value;

    let price = this.sizes.length == 0 || this.sizes.length == 1 && this.sizes[0].sizeLabel.trim() == "" ? this.controller.food.price : this.selectedSizePrice;

    let totalAmount = (parseInt(quantity) * (parseFloat(price) + parseFloat(<any>this._price) + parseFloat(<any>this._ingredient_price)));

    document.getElementById("totalAmount")!.innerHTML = this.currencyService.formatNumber(totalAmount);

  }

  colorIcons() {

    let icons = document.getElementsByClassName("fa-plus");

    if (this.controller.flavors == this.controller.food.flavorsQuantity) {
      
      for (let i = 0; i < icons.length; i++) {

        document.getElementById("plusIcon" + i)!.style.color = "#ccc";

          if (parseInt((<any>document.getElementById("flavorQuantity" + i)!).value) > 0) {

          document.getElementById("minusIcon" + i)!.style.color = this.controller.primaryColor;

        }

      }

    } else {
      
      for (let i = 0; i < icons.length - 1; i++) {

        let icon = document.getElementById("plusIcon" + i);

        document.getElementById("plusIcon" + i)!.style.color = this.flavors == this.controller.food.flavorsQuantity ? "#ccc" : this.controller.primaryColor;

        if (parseInt((<any>document.getElementById("flavorQuantity" + i)!).value) == 0) {

          document.getElementById("minusIcon" + i)!.style.color = "#ccc";

        } else {
          
          document.getElementById("minusIcon" + i)!.style.color = this.controller.primaryColor;

        }

      }

    }

  }

  _plus(i: any, price: any, id: any) {

    let quantity = (<any>document.getElementById("quantity" + i)!).value;
    let complementName = (document.getElementById("complement_com" + i)!).innerHTML;

    let complementQuantity = parseInt(quantity) + 1;

    (<any>document.getElementById("quantity" + i)!).value = complementQuantity;

    document.getElementById("_minusIcon" + i)!.style.color = this.controller.primaryColor;

    this._price += parseFloat(price);

    this.calcTotalAmount();

    let obj: any = new Object();
    obj.id = id;
    obj.name = complementName;
    obj.quantity = complementQuantity;
    obj.price = price;
    obj.type = "com";

    for (let j = 0; j < this.complementsArr.length; j++) {

      if (this.complementsArr[j].id == obj.id && this.complementsArr[j].type == obj.type) {

        this.complementsArr.splice(j, 1);

      }

    }

    this.complementsArr.push(obj);

  }

  _minus(i: any, price: any, id: any) {

    let quantity = (<any>document.getElementById("quantity" + i)!).value;
    let complementName = (<any>document.getElementById("complement_com" + i)!).innerHTML;

    let complementQuantity = parseInt(quantity) - 1;

    if (quantity > 0) {

      (<any>document.getElementById("quantity" + i)!).value = complementQuantity;

      this._price -= parseFloat(price);

      this.calcTotalAmount();

      let obj: any = new Object();
      obj.id = id;
      obj.name = complementName;
      obj.quantity = complementQuantity;
      obj.price = price;
      obj.type = "com";

      for (let j = 0; j < this.complementsArr.length; j++) {

        if (this.complementsArr[j].id == obj.id && this.complementsArr[j].type == obj.type) {

          this.complementsArr.splice(j, 1);

        }

      }

      if (complementQuantity > 0) {

        this.complementsArr.push(obj);

      }

    }
    
    if (quantity - 1 == 0) {

      document.getElementById("_minusIcon" + i)!.style.color = "#ccc";

    }

  }

  _ingredient_plus(i: any, price: any, id: any) {

    let quantity = (<any>document.getElementById("ingredient_quantity" + i)!).value;
    let ingredientName = (<any>document.getElementById("ingredient_com" + i)!).innerHTML;

    let ingredientQuantity = parseInt(quantity) + 1;

    (<any>document.getElementById("ingredient_quantity" + i)!).value = ingredientQuantity;
    
    (document.getElementById("_ingredient_minusIcon" + i)!).style.color = this.controller.primaryColor;

    this._ingredient_price += parseFloat(price);

    this.calcTotalAmount();

    let obj: any = new Object();
    obj.id = id;
    obj.name = ingredientName;
    obj.quantity = ingredientQuantity;
    obj.price = price;
    obj.type = "com";

    for (let j = 0; j < this.ingredientsArr.length; j++) {

      if (this.ingredientsArr[j].id == obj.id && this.ingredientsArr[j].type == obj.type) {

        this.ingredientsArr.splice(j, 1);

      }

    }

    this.ingredientsArr.push(obj);

  }

  _ingredient_minus(i: any, price: any, id: any) {

    let quantity = (<any>document.getElementById("ingredient_quantity" + i)!).value;
    let ingredientName = (<any>document.getElementById("ingredient_com" + i)!).innerHTML;

    let ingredientQuantity = parseInt(quantity) - 1;

    if (quantity > 0) {

      (<any>document.getElementById("ingredient_quantity" + i)!).value = ingredientQuantity;

      this._ingredient_price -= parseFloat(price);

      this.calcTotalAmount();

      let obj: any = new Object();
      obj.id = id;
      obj.name = ingredientName;
      obj.quantity = ingredientQuantity;
      obj.price = price;
      obj.type = "com";

      for (let j = 0; j < this.ingredientsArr.length; j++) {

        if (this.ingredientsArr[j].id == obj.id && this.ingredientsArr[j].type == obj.type) {

          this.ingredientsArr.splice(j, 1);

        }

      }

      if (ingredientQuantity > 0) {

        this.ingredientsArr.push(obj)

      }

    }

    if (quantity - 1 == 0) {

      (document.getElementById("_ingredient_minusIcon" + i)!).style.color = "#ccc";

    }

  }

  _ingredient_sem(i: any, id: any) {

    let radioSelected_minus = (<any>document.getElementById("ingredientCheckbox" + i + "_minus"))["checked"];

    let ingredientName = document.getElementById("ingredient_com" + i)!.innerHTML;

    let obj: any = new Object();
    obj.id = id;
    obj.name = ingredientName;
    obj.quantity = 1;
    obj.price = 0;
    obj.type = "sem";
    obj.addOrRemove = false;

    for (let j = 0; j < this.ingredientsArr.length; j++) {

      if (this.ingredientsArr[j].id == obj.id && this.ingredientsArr[j].type == obj.type) {

        this.ingredientsArr.splice(j, 1);

      }

    }

    if (radioSelected_minus) {

      this.ingredientsArr.push(obj);

    }

  }


  toggleCS(com: boolean) {
    
    let csText = document.getElementById("csText");
    let csText_sem = document.getElementById("csText_sem");

    let com_div: any = document.getElementsByClassName("com_div");
    let sem_div: any = document.getElementsByClassName("sem_div");

    if (!com) {
      
      csText!.style.background = "white";
      csText_sem!.style.background = this.controller.primaryColor;

      csText!.style.color = this.controller.titleColor2;
      csText_sem!.style.color = "white";

      for (let i = 0; i < com_div.length; i++) {

        com_div[i].style.display = "none";
        sem_div[i].style.display = "block";

      }

    } else {

      csText!.style.background = this.controller.primaryColor;
      csText_sem!.style.background = "white";

      csText!.style.color = "white";
      csText_sem!.style.color = this.controller.titleColor2;

      for (let i = 0; i < com_div.length; i++) {

        com_div[i].style.display = "block";
        sem_div[i].style.display = "none";

      }

    }

  }

  calcCommentQuantity() {

    setTimeout(function () {

      document.getElementById("commentQuantity")!.innerHTML = (<any>document.getElementById("commentTextarea")!).value.length;

    }, 100);

  }

  bottomBlockBlack() {

    document.getElementById('bottom-block-black')!.style.animationName = 'opacity-reverse';
    document.getElementById('bottom-block-content')!.style.animationName = 'bottom-reverse';
    setTimeout(function () {
      document.getElementById('bottom-block-black')!.style.display = 'none';
      document.getElementById('bottom-block-content')!.style.display = 'none'
    }, 250)

  }

  bottomBlockA() {

    document.getElementById('bottom-block-black')!.style.animationName = 'opacity-reverse';
    document.getElementById('bottom-block-content')!.style.animationName = 'bottom-reverse';
    setTimeout(function () {
      document.getElementById('bottom-block-black')!.style.display = 'none';
      document.getElementById('bottom-block-content')!.style.display = 'none'
    }, 250)

  }

  getValue(n: any) {

    return this.currencyService.formatNumber(n);

  }

  getFlavorValue(flavor: any) {

    if (this.controller.food.flavorsQuantity > 1) {

      return this.currencyService.formatNumber(<number><unknown>this.getFlavorPrice(flavor));

    }

    return this.currencyService.formatNumber(flavor.price / this.controller.food.flavorsQuantity);
    
  }

  getFlavorPrice(flavor: any) {

    let sizes: FoodSize[] = JSON.parse(flavor.sizes);

    let foodSizes: FoodSize[] = JSON.parse(this.controller.food.sizes);

    let defaultPrice = flavor.price / this.controller.food.flavorsQuantity;

    if (this.controller.food.flavorsQuantity > 1 && foodSizes != null && foodSizes != undefined && foodSizes.length > 1 && !(foodSizes[0].sizeLabel.trim() == "")) {
      
      if (sizes != null && sizes != undefined && sizes.length > 0) {
        
        for (let size of sizes) {
          
          if (size.sizeLabel == this.selectedSize) {

            let result = parseFloat(<string><unknown>size.price) / this.controller.food.flavorsQuantity;

            if (isNaN(result)) {

              return defaultPrice;

            }

            return parseFloat(<string><unknown>size.price) / this.controller.food.flavorsQuantity;

          }

        }

        return defaultPrice

      } else {

        return defaultPrice;

      }

    }

    return flavor.price / this.controller.food.flavorsQuantity;

  }

}
