import { ElementRef, Injectable} from '@angular/core'
import { FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  constructor() {

  }

  public displayCoreSpinner(display: boolean, elementRef: ElementRef) {

    if (display) {

      elementRef.nativeElement.ownerDocument.body.getElementsByTagName('app-root')[0].style.opacity = '0';

      setTimeout(function () {

        document.getElementById("core-spinner")!.style.display = "block";

      }, 1);

    } else {

      elementRef.nativeElement.ownerDocument.body.getElementsByTagName('app-root')[0].style.opacity = '1';

      setTimeout(function () {

        document.getElementById("core-spinner")!.style.display = "none";

      }, 1);

    }

  }

}
