<div class="app" sty>
  
  <div class="wrapper">
    <div id="chat-area" class="chat-area">
      <div id="chat-area-header" class="chat-area-header">
        <div class="chat-area-title">Tire suas dúvidas com o nosso robozinho</div>

      </div>
      <div id="chat-area-main" class="chat-area-main">

        <!--<div class="chat-msg owner">
        <div class="chat-msg-profile">

          <div class="chat-msg-date">Message seen 2.50pm</div>
        </div>
        <div class="chat-msg-content">
          <div class="chat-msg-text">Tincidunt arcu non sodales😂</div>
        </div>
      </div>
      <div class="chat-msg">
        <div class="chat-msg-profile">

          <div class="chat-msg-date">Message seen 3.16pm</div>
        </div>
        <div class="chat-msg-content">
          <div class="chat-msg-text">Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et🥰</div>
        </div>
      </div>-->
      </div>

    </div>
    <div id="chat-area-footer" class="chat-area-footer" style="position: fixed; bottom: 0px">
      <input id="inputField" type="text" placeholder="Digite uma mensagem" style="background: #f5f6f7" />
      <svg (click)="askQuestionInput()" xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="#6e6e6e"><path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" /></svg>
    </div>
    <button id="copyKeyMessage" (click)="copyKeyMessage()" style="display:none">Click me</button>
    <button id="copyCodeMessage" (click)="copyCodeMessage()" style="display:none">Click me</button>
  </div>
</div>
