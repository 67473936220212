import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  public static formatNumber(n: number) {

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    })

    return formatter.format(n);

  }

  public formatNumber(n: number) {

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    })

    return formatter.format(n);

  }

  public getCurrencyFormatNumbersOnly(currencyCode: any) {

    return {

      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'none',

    }

  }

  /*
   * Usage:
   *
   * const format = getCurrencyFormatNumbersOnly('JPY')
   * formatCurrency(12345, format, 'ja')
   * formatCurrency(123456, format, 'ja')
   * formatCurrency(1234567, format, 'ja')
   * formatCurrency(12345678, format, 'ja')
   *
   */

  public formatNumberWithoutCurrency(n: number) {

    const format = this.getCurrencyFormatNumbersOnly('BRL');
    return this.formatCurrency(n, format, 'pt');

  };

  public formatCurrency(value: number, format: any, lang: any) {

    const stripSymbols = (format.currencyDisplay === 'none')
    const localFormat = stripSymbols ? { ...format, currencyDisplay: 'code' } : format
    let result = Intl.NumberFormat(lang, localFormat).format(value)

    if (stripSymbols) {
      result = result.replace(/[a-z]{3}/i, "").trim()
    }

    return result

  }

}
