import { Component, OnInit} from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../services/global.service';
import { URLService } from '../services/url.service';
import { Menu } from '../dto/Menu';

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
  standalone: true,
  imports: [MatDialogModule, MatListModule, CommonModule, MatButtonModule],
})
export class DialogContentExampleDialog implements OnInit {

  districtsDeliveryFeeObjects = new Array();

  menu: any = new Object();

  constructor(private httpClient: HttpClient, private globalService: GlobalService, private urlService: URLService,
    private dialogRef: MatDialog) {

  }

  ngOnInit(): void {

    let that = this;

    let storename = this.urlService.getParameter(window.location.href, "loja");

    that.httpClient.get(that.globalService.url + "/get-menu-by-name?name=" + storename).subscribe((response) => {

      let menu = <Menu>response;

      that.menu = menu;

      that.districtsDeliveryFeeObjects = JSON.parse(menu.districtsDeliveryFeeObjects);
      
    })

  }

  select(item: any) {

    localStorage.setItem("districtDeliveryFee", item.price);
    localStorage.setItem("chefsuite-district", item.district);

    document.getElementById("getDeliveryFeeMoney")!.click();

    this.dialogRef.closeAll();

  }

}
