<div id="app" style="height: 100%">


  <div class="view view-main view-init ios-edges" data-url="/">

    <div class="page">
      <div class="navbar" [style]="'background: #f5f6f7 !important'">
        <div class="navbar-inner sliding navbar-pages" style=" height: 57px; background: #f5f6f7 !important">

          <div id="back-icon" class="left" (click)="gotoMenu()"
               style="z-index: 999; position: fixed; margin: 15px; padding-left: 10px; padding-right: 20px; border-radius: 50%">

            <i class="fa fa-angle-left"
               [style]="'color:' + controller.primaryColor + ' !important; font-size: 22pt; height: 100%; padding: 5px; position: relative; right: 10px'">
            </i>
          </div>

          <div class="title sliding title-heading"
               style="position: fixed; width: 100%; margin: 0; margin-top:10px">
            <h5 id="header-text"
                [style]="'color:' + controller.secondaryColor + ' !important; width: fit-content; margin-left: auto; margin-right: auto;font-size:16px;font-weight:bold'" class="nc-small">
              MEUS PEDIDOS
            </h5>
          </div>
          <div id="clear" class="right"
               style="position: absolute; right: 16px">
          </div>
        </div>
      </div>

      <div *ngIf="currentOrder == null && (orders == null || orders == undefined || orders.length == 0)" style="float: left; width: 100%; height: 150px; margin-bottom: 75px; margin-top: 50%">
        <div style=" display: flow-root; margin-top: 15px; padding: 15px; border-radius: 4px; padding-top: 15px; padding-bottom: 15px; padding-left: 8px; padding-right: 8px; background: white; padding: 15px; width:calc(100% - 60px); margin-left: auto; margin-right: auto; margin-top: 65px">
          <!--<h1 [hidden]="orders == null || orders == undefined || orders.length == 0" [style]="'color:' + controller.titleColor + ';font-size:12pt;margin-bottom:5px'">Peça de novo</h1>-->
          <p [hidden]="!(orders == null || orders == undefined || orders.length == 0)" style="text-align: center" [style]="'color:' + controller.titleColor" class="nc-normal">Nenhum pedido no histórico</p>
          <!--<div style="display: block; float: left; width:100%" *ngIf="orders != null && orders != undefined && orders.length > 0">
            <div *ngFor="let fi of lastOrder.foodInformations">
              <span style="font-size: 9pt; padding: 2px; border-radius: 4px; background: #f2f2f2">{{fi.quantity}}</span>
              <span style="margin-left: 4px;font-size: 9pt">{{fi.food.name}}</span>
            </div>
            <div style="margin-top: 15px; font-size: 9pt">
              <h5 (click)="addToCart(lastOrder.foodInformations)" [style]="'color: white !important; padding: 15px; text-align: center; border-radius: 4px;padding-right: 8px; font-size: 9pt; background:' + controller.primaryColor">Adicionar à sacola</h5>
            </div>
          </div>-->
        </div>
      </div>

      <div id="current-order-section" [style]="currentOrder == null || currentOrder == undefined ? 'display: none' : 'display: block'" style="float: left; width: 100%; background: rgb(242, 242, 242) !important; margin-bottom: 30px">
        <div style="display:block;width:100%;height:133px;top:133px;background:white;position:absolute"></div>
        <div style="position:relative;box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; display: flow-root; margin-top: 15px; padding: 15px; border-radius: 4px; padding-top: 15px; padding-bottom: 15px; padding-left: 8px; padding-right: 8px; background: white; padding: 15px; width:calc(100% - 60px); margin-left: auto; margin-right: auto; margin-top: 65px">
          <div (click)="gotoDetails()">
            <span class="nc-micro" [style]="'color:' + controller.titleColor">Previsão de entrega</span>
            <div style="float: right">
              <svg [style]="'display:block;width: 14px; fill: #50A773; position: relative;margin-right:5px;top:2px'" class="fa-beat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>
              <svg [style]="'display:block;width: 14px; fill: #50A773; position: relative;margin-right:5px;top:2px;margin-top:-14px'" class="fa-beat-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>
            </div>
            <div [style]="'color:' + controller.titleColor + ';font-size:14pt;margin-bottom:5px'" class="nc-normal">Hoje, {{getForecastTime()}}</div>

            <div style="display: block; float: left; width:100%;margin-top:10px">

              <!--<div *ngIf="controller.logo != null && controller.logo.trim() != ''"
                   style="display: block; width: fit-content; float: left; margin-right: 8px">
                <img [src]="controller.logo" width="40" height="40"
                     style="border-radius: 50%">
              </div>-->
              <div id="more-items" [style]="controller.logo == null || controller.logo.trim() == '' ? '' : 'width:calc(100% - 68px);float: left'">
                <h2 [innerHTML]="controller.companyName"
                    [style]="'color:' + controller.titleColor + '; margin-bottom: 0px;font-weight:bold;font-size:12pt'">
                </h2>

                <svg *ngIf="!isTakeout()" [style]="'width: 14px; fill: ' + controller.primaryColor + '; position: relative;margin-right:5px;top:2px'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" /></svg>

                <svg *ngIf="isTakeout()" [style]="'width: 14px; fill: ' + controller.primaryColor + '; position: relative;margin-right:5px;top:2px'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M160 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM126.5 199.3c-1 .4-1.9 .8-2.9 1.2l-8 3.5c-16.4 7.3-29 21.2-34.7 38.2l-2.6 7.8c-5.6 16.8-23.7 25.8-40.5 20.2s-25.8-23.7-20.2-40.5l2.6-7.8c11.4-34.1 36.6-61.9 69.4-76.5l8-3.5c20.8-9.2 43.3-14 66.1-14c44.6 0 84.8 26.8 101.9 67.9L281 232.7l21.4 10.7c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3L247 287.3c-10.3-5.2-18.4-13.8-22.8-24.5l-9.6-23-19.3 65.5 49.5 54c5.4 5.9 9.2 13 11.2 20.8l23 92.1c4.3 17.1-6.1 34.5-23.3 38.8s-34.5-6.1-38.8-23.3l-22-88.1-70.7-77.1c-14.8-16.1-20.3-38.6-14.7-59.7l16.9-63.5zM68.7 398l25-62.4c2.1 3 4.5 5.8 7 8.6l40.7 44.4-14.5 36.2c-2.4 6-6 11.5-10.6 16.1L54.6 502.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L68.7 398z" /></svg>

                <span class="nc-micro" [style]="'color:' + controller.titleColor">{{isTakeout() ? "Retirada no local" : "Entregue pela loja"}}</span>

              </div>



            </div>

          </div>
          <div style="display:block;float:left;width:calc(100% - 44px);margin-top: 15px;margin-left:22px; font-size: 9pt">
            <h5 [style]="'float:left;color:' + controller.primaryColor + ' !important;text-align: right; font-size: 11pt'" (click)="callWhatsapp()" class="nc-micro-bold">Chamar no WhatsApp</h5>
            <h5 (click)="gotoDetails()" [style]="'float:right;color:' + controller.primaryColor + ' !important;text-align: right; font-size: 11pt'" class="nc-micro-bold">Acompanhar</h5>
          </div>
        </div>

      </div>

      <div id="ask-again-content" *ngIf="!(orders == null || orders == undefined || orders.length == 0)"
           style="display:inline-block;margin-bottom: 25px; margin-left: 15px; width: calc(100% - 30px); margin-top:25px">

        <h1 [style]="'color:' + controller.titleColor + ';font-size:12pt'" class="nc-normal">Histórico</h1>

        <div *ngFor="let order of orders; let i = index" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; display: flow-root; margin-top: 15px; padding: 15px; border-radius: 4px; padding-top: 15px; padding-bottom: 15px; padding-left: 8px; padding-right: 8px">
          <div style="margin-left:8px; margin-bottom: 2px; font-size: 9pt;">
            <svg style="display:block;float:left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="18px"><path fill="#50A773" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
            <span style="margin-left: 7px;display:block;float:left" class="nc-small" [style]="'color:' + controller.titleColor">Pedido concluído</span>
          </div>
          <div style="display: block; float: left; width:100%">
            <div *ngFor="let fi of order.foodInformations">
              <span class="nc-micro" style="margin-left: 8px;font-size: 9pt; padding: 2px; border-radius: 4px; background: #f5f6f7" [style]="'color:' + controller.titleColor">{{fi.quantity}}</span>
              <span style="margin-left: 4px;font-size: 9pt" class="nc-micro" [style]="'color:' + controller.titleColor">{{fi.food.name}}</span>

            </div>
            <span style="margin-left: 8px;font-size: 9pt;width:100%" class="nc-micro" [style]="'color:' + controller.titleColor">{{dateService.millisToLongDate(order.date)}}</span>
            <div style="display:none;margin-top: 15px; font-size: 9pt">
              <h5 (click)="addToCart(order.foodInformations)" [style]="'color:' + controller.primaryColor + ' !important; margin-left: 8px;text-align: right; padding-right: 8px; font-size: 9pt'">Adicionar à sacola</h5>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
