import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './cart/cart.component';
import { MComponent } from './m/m.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { OrdersComponent } from './orders/orders.component';
import { StoreProfileComponent } from './store-profile/store-profile.component';
import { OrderDetailsComponent } from './order-details/order-details.component';

const routes: Routes = [
  { path: '', component: MComponent },
  { path: 'p', component: ProductDetailsComponent },
  { path: 'c', component: CartComponent },
  { path: 'pedidos', component: OrdersComponent },
  { path: 'perfil', component: StoreProfileComponent },
  { path: 'detalhes', component: OrderDetailsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
