<div id="app" style="height: 100%">


  <div class="view view-main view-init ios-edges" data-url="/">

    <div class="page">
      <div class="navbar" [style]="'background: white'">
        <div class="navbar-inner sliding navbar-pages" style=" height: 57px; background: white">

          <div id="back-icon" class="left"
               style="z-index: 999; position: fixed; margin: 15px; padding-left: 10px; padding-right: 20px; border-radius: 50%; background: white; width: 2px">
            <i class="fa fa-angle-down" (click)="gotoMenu()"
               [style]="'color:' + controller.secondaryColor + ' !important; font-size: 22pt; height: 100%; padding: 5px; position: relative; right: 10px'">
            </i>
          </div>

          <div class="title sliding title-heading"
               style="position: fixed; width: 100%; margin: 0; margin-top:10px">
            <h5 id="header-text" class="nc-small"
                [style]="'color:' + controller.secondaryColor + ' !important; width: fit-content; margin-left: auto; margin-right: auto;font-size:16px;font-weight:bold'">
              SACOLA
            </h5>
          </div>
          <div id="clear" (click)="clearCart()" class="right nc-small"
               style="position: absolute; right: 16px"
               [hidden]="showDialog">
            <span class="nc-micro-bold" [style]="'font-weight: bold;font-size: 9pt; color:' + controller.primaryColor">Limpar</span>
          </div>
        </div>
      </div>
      <div class="page-content" style="margin-top: 57px">

        <!-- cart -->
        <div class="segments-page">
          <div class="container" [hidden]="showDialog"
               style="display: inline-block">

            <div *ngIf="controller.logo != null && controller.logo.trim() != ''"
                 style="display: block; width: fit-content; float: left; margin-right: 8px">
              <img [src]="controller.logo" width="40" height="40"
                   style="border-radius: 50%">
            </div>
            <div id="more-items" [style]="controller.logo == null || controller.logo.trim() == '' ? '' : 'width:calc(100% - 48px);float: left'">
              <h4 [innerHTML]="controller.companyName" class="nc-small-bold"
                  [style]="'color:' + controller.titleColor + '; margin-bottom: 0px;font-weight:bold'">
              </h4>
              <h5 class="nc-small-bold" (click)="gotoMenu()"
                  [style]="'font-weight: bold;margin-bottom:25px;color:' + controller.primaryColor">
                Adicionar mais itens
              </h5>
            </div>

          </div>
          <div class="container">
            <h4 [style]="'color:' + controller.titleColor + '; margin-bottom: 0px;font-weight:bold;font-size:18px;margin-bottom:15px'" class="nc-small-bold">
              Itens adicionados
            </h4>


            <div id="cart-items" class="cart" style="display: none">
              <div class="cart-product first"
                   *ngFor="let food of foods">
                <div class="row" style="display: flow-root">
                  <div class="col-70" style="width: 70%">
                    <div class="contents">
                      <img [src]="food.base64Image"
                           style="width: 75px; display: block; float: left">
                    </div>
                    <div style="display: block; float: left; margin-left: 10px; max-width:calc(100% - 85px)">
                      <a href=""
                         [style]="'color:' + controller.titleColor + ' !important'" class="nc-micro">{{food.name}}</a>
                      <br />
                      <p class="price nc-micro-bold"
                         [style]="'color:' + controller.titleColor"><b>{{formatNumber(food.price)}}</b><br><i>{{food.comments != null && food.comments != undefined && food.comments.trim() != "" ? "Obs.: " + food.comments : ""}}</i></p>


                      <div style="font-weight: bold; font-size: 9pt"
                           [hidden]="!(food.flavors.length > 0)"
                           [style]="'color:' + controller.titleColor + ' !important'" class="nc-micro">SABORES</div>
                      <div *ngFor="let flavor of food.flavors_"
                           style="font-size: 8pt" class="nc-micro"
                           [style]="'color:' + controller.titleColor + ' !important'">
                        1/{{food.flavors_.length}} {{flavor.name}}
                      </div>

                      <div style="font-weight: bold; font-size: 9pt"
                           [hidden]="!(food.complements.length > 0)" class="nc-micro"
                           [style]="'color:' + controller.titleColor + ' !important;font-weight:bold'">ADICIONAIS</div>
                      <div *ngFor="let complement of food.complements"
                           style="font-size: 8pt" class="nc-micro"
                           [style]="'color:' + controller.titleColor + ' !important'">
                        {{
complement.type == "com" ? "C/ " + complement.quantity :
													"S/"
                        }} {{complement.name}}
                      </div>


                      <div style="font-weight: bold; font-size: 9pt" class="nc-micro"
                           [hidden]="!(food.ingredients.length > 0)"
                           [style]="'color:' + controller.titleColor + ' !important; margin-top: 15px;font-weight:bold'">INGREDIENTES</div>
                      <div *ngFor="let ingredient of food.ingredients"
                           style="font-size: 8pt" class="nc-micro"
                           [style]="'color:' + controller.titleColor + ' !important'">
                        {{
ingredient.type == "com" ? "C/ " + ingredient.quantity :
													"S/"
                        }} {{ingredient.name}}
                      </div>


                    </div>

                  </div>
                  <div class="col-30" style="width: 30%; float: right">
                    <div class="contents remove">
                      <!--<div style="position: relative; top: -12px">
                        <i class="fa fa-remove" (click)="remove(food.id)"></i>
                      </div>-->

                      <span style="position: relative; float: left; display: block; border: 1px solid #ccc; border-radius: 4px; height: 20px; padding: 10px">
                        <i [id]="'_minusIcon' + food.id" class="fa fa-minus"
                           (click)="_minus(food.id)"
                           style="display: block; float: left; color: #ccc; font-size: 16px; font-weight: bold; margin-left: 5px"></i>
                        <input [id]="'quantity' + food.id" readonly="true"
                               type="number" [value]="food.quantity" class="nc-micro"
                               style="display: block; float: left; width: 20px; text-align: center; width: 60%; border: 0 !important;color:#6e6e6e">

                        <i [id]="'_plusIcon' + food.id" class="fa fa-plus"
                           (click)="_plus(food.id)"
                           style="display: block; float: left; color: #ccc; font-size: 16px; font-weight: bold;"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div id="minimum-order-price" [style]="'background: rgb(252,187,0,0.1); color: #7D6D3E; border-radius: 8px; padding: 15px; margin-bottom: 15px; display:' + (isMinimumOrderAvailable() ? 'flex' : 'none')">
              <div style="display:block;float:left">
                <i class="fa fa-exclamation-triangle" style="margin-top:10px"></i>
              </div>
              <div style="display:block;float:left;margin-left:15px" class="nc-micro">
                O pedido mínimo dessa loja é
                <strong>{{formatNumber(controller.minimumOrder)}}</strong> sem contar com a taxa de entrega
              </div>
            </div>

            <div *ngIf="recommendations.length >= 4">
              <div class="nc-small-bold" style="margin-bottom:0px">Combine com seu pedido</div>
              <div style="display: flex;overflow-x: auto;margin-bottom:25px">

                <div *ngFor="let fi of recommendations" style="display: block; float: left;margin-right:15px;margin-top: -25px">
                  <div (click)="add(fi.food)" style="height:40px;width:40px;border-radius:40px;background:white;position:relative;top:45px;left:45px">
                    <svg [style]="'width:15px;display: block; float: left; color:' + controller.primaryColor + '!important; font-size: 16px; font-weight: bold; margin-right:5px;position:relative;top:12px;left:12px'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path class="plus-path" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                  </div>

                  <img *ngIf="fi.food.base64Image != null && fi.food.base64Image != undefined && fi.food.base64Image.trim() != ''"
                       width="90" height="90" style="object-fit:cover;border-radius:25px"
                       [src]="fi.food.base64Image" alt="">
                  <img *ngIf="fi.food.base64Image == null || fi.food.base64Image == undefined || fi.food.base64Image.trim() == ''"
                       width="90" height="90" style="object-fit:cover;border-radius:25px"
                       [src]="getBase64Image()" alt="">

                  <div class="nc-micro-bold">{{formatNumber(fi.food.price)}}</div>
                  <div class="nc-micro" style="word-break: break-all">{{cutString(fi.food.name)}}</div>
                </div>
              </div>
            </div>

            <div id="total-pay" class="total-pay"
                 [style]="'background:' + '#fff' + ' !important;margin-bottom:50px; border-radius:8px;padding:0;padding-bottom: 50px'">
              <h2 style="font-weight:500;font-size:18px;margin:0" class="nc-normal" [style]="'color:' + controller.titleColor + ' !important'">Resumo de valores</h2>
              <!--<div class="row" *ngFor="let food of foods"
                                 style="margin-bottom: 0 !important; line-height: 10px !important">
                              <div class="col-70" style="width: 70%">
                                <div class="contents">
                                  <p [style]="'color:' + controller.titleColor + ' !important'">
                                    {{food.name}}
                                  </p>
                                </div>
                              </div>
                              <div class="col-30" style="width: 30%; float: right">
                                <div class="contents right">
                                  <p [id]="'food' + food.id"
                                     [style]="'color:' + controller.titleColor + ' !important'">
                                    {{
              formatNumber(getFoodPrice(food.price,
                                      food.quantity))
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>-->
              <div class="row">
                <div class="col-70" style="width: 70%">
                  <div class="contents">
                    <h5 [style]="'color:' + controller.titleColor + ' !important'" class="nc-micro-bold"><b>Subtotal</b></h5>
                  </div>
                </div>
                <div class="col-30" style="width: 30%; float: right">
                  <div class="contents right">
                    <h5 id="subtotal" class="nc-micro-bold"
                        [style]="'color:' + controller.titleColor + ' !important;font-weight:bold'">
                      {{formatNumber(getSubTotal())}}
                    </h5>
                  </div>
                </div>
                <div class="col-70" style="width: 70%">
                  <div class="contents">
                    <h5 [style]="'color:' + controller.titleColor + ' !important'" class="nc-micro">
                      Taxa
                      de entrega
                    </h5>
                  </div>
                </div>
                <div class="col-30" style="width: 30%; float: right">
                  <div class="contents right">
                    <h5 [style]="(!isTakeout() && getDeliveryFeeMoney() == 0 ? 'font-weight:600 !important;color:' + controller.descriptionColor : 'color:' + controller.titleColor) + ' !important'" class="nc-micro">{{isTakeout() ? formatNumber(0) : getDeliveryFeeMoney() == 0 ? 'Grátis' : formatNumber(getDeliveryFeeMoney())}}</h5>
                  </div>
                </div>
                <div class="col-70" style="width: 70%">
                  <div class="contents">
                    <h5 [style]="'color:' + controller.titleColor + ' !important'" class="nc-micro">Taxa de serviço</h5>
                  </div>
                </div>
                <div class="col-30" style="width: 30%; float: right">
                  <div class="contents right">
                    <h5 [style]="'color:' + controller.titleColor + ' !important'" class="nc-micro">{{formatNumber(getServicesMoney())}}</h5>
                  </div>
                </div>
                <div class="col-70" style="width: 70%">
                  <div class="contents">
                    <h5 [style]="'color:' + controller.titleColor + ' !important'" class="nc-micro">Desconto</h5>
                  </div>
                </div>
                <div class="col-30" style="width: 30%; float: right">
                  <div class="contents right">
                    <h5 [style]="'color:' + controller.titleColor + ' !important'" class="nc-micro">{{formatNumber(getDiscountsMoney())}}</h5>
                  </div>
                </div>
                <!--<div class="col-70" style="width: 70%">
                  <div class="contents">
                    <h5 [style]="'color:' + controller.titleColor + ' !important'" class="nc-micro-bold">
                      <b>Total</b>
                    </h5>
                  </div>
                </div>
                <div class="col-30" style="width: 30%; float: right">
                  <div class="contents right">
                    <h5 id="total" class="nc-micro-bold"
                        [style]="'font-weight: bold;color:' + controller.titleColor + ' !important'">
                      <b>{{formatNumber(getTotal())}}</b>
                    </h5>
                  </div>
                </div>-->
              </div>

              <!--<div class="row">
                <div class="col-lg-10">
                </div>
                <div class="col-lg-2" style="width:100%">
                  <button id="realizarPedidoBtn" type="button"
                          (click)="openShowDialog()"
                          [style]="'background:' + controller.primaryColor + ' !important;' + 'color:white !important; border: unset !important'"
                          class="form-control nc-micro" style="width:100%">
                    Continuar
                  </button>
                </div>
              </div>-->

            </div>
          </div>
        </div>
        <!-- end cart -->
      </div>
    </div>
    <div id="paymentDialog" class="modal-body"
         [style]="'display: none;opcity:0;height: calc(100% - 90px);background: white;position: absolute;
    top: 50px;
    border-radius: 4px;overflow-y: auto; padding: 15px; width: calc(100% - 30px)'">

      <div class="row">
        <div class="col-lg-11">
        </div>
        <div class="col-lg-1">
          <div
               (click)="closePaymentDialog()"
               style="text-decoration: none; float: right;cursor:pointer">
            <i style="font-size: 12pt" class="fa fa-remove"></i>
          </div>
        </div>
      </div>

      <h4 [style]="'color:' + controller.titleColor + '; margin-bottom: 0px;font-weight:bold;font-size:18px;margin-bottom:15px'" class="nc-small-bold">
        Escolha o tipo de entrega
      </h4>
      <div class="tab"
           style="background: none;border:0; margin-bottom: 15px">
        <button class="tablinks nc-micro" id="tablinks0" (click)="selectColor(0)"
                [style]="'background:' + controller.primaryColor + ';color:#fff; width:' + (isCustomDeliveryObjectsUnavailable() ? '50%' : '32%') + ' !important; padding: 14px 10px; border-top-left-radius:4px;border-bottom-left-radius:4px'">
          Por
          endereço
        </button>

        <button class="tablinks nc-micro" id="tablinks1" (click)="selectColor(1)"
                [style]="(isCustomDeliveryObjectsUnavailable() ? 'width:50%;border-top-right-radius:4px;border-bottom-right-radius:4px' : 'width:36%') + ' !important; padding: 14px 10px;border-top-right-radius:4px;border-bottom-right-radius:4px'">
          Retirada
          no local
        </button>
        <button [hidden]="isCustomDeliveryObjectsUnavailable()" class="tablinks nc-micro" id="tablinks2" (click)="selectColor(2)"
                style="width: 32% !important; padding: 14px 10px">
          Personalizada
        </button>
        <button id="fillFieldsBtn" style="display: none"
                (click)="fillFields()"></button>
      </div>
      <div id="por-endereco" class="tabcontent">
        <form (submit)="orderPaymentType == 'CREDIT_CARD' && menu.pagSeguroToken != null && menu.pagSeguroToken != undefined && menu.pagSeguroToken.trim() != '' ? confirm() : presentMessage()" ngNativeValidate>
          <div>
            <div *ngIf="hasAddress()">
              <div *ngFor="let address of addresses;let i = index" style="display:flex;border: 1px solid black; border-radius: 8px;margin-top:8px" [style]="'border:2px solid ' + getSelectedAddressColor(address.address)">
                <div style="display: block; float:left; color: #6e6e6e;padding:15px;width:calc(100% - 90px)" (click)="selectAddress(address)" [style]="'color:' + controller.titleColor + ' !important'">{{address.address}}, {{address.number}} - {{address.district}}</div>
                <div style="display: block; float:right;padding:15px">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="12px"><path [id]="'path1-' + address.id" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
                  <svg [matMenuTriggerFor]="menu" style="margin-left: 15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" height="12px"><path [id]="'path2-' + address.id" [class]="address.address" d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" /></svg>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editAddress(address, i)">Editar</button>
                    <button mat-menu-item (click)="deleteAddress(address, i)" *ngIf="address.id != null && address.id != undefined">Excluir</button>
                  </mat-menu>
                </div>
              </div>
            </div>
            <div style="display:block;width:100%;padding-bottom:15px; color: #6e6e6e; margin-top: 15px">
              <div style="display:block;float:right" *ngIf="addresses != null && addresses.length != undefined && addresses.length < 3 && hasAddress()" (click)="editAddress_()">
                Novo endereço
                <svg style="margin-left: 5px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="12px"><path fill="#6e6e6e" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg>
              </div>
            </div>
            <div *ngIf="!hasAddress()">

              <div class="row" style="width: 100%;display:block">
                <div class="col-lg-12">
                  <label [style]="'color:' + controller.titleColor" class="nc-small">Endereço<span style="color: red">*</span></label>
                  <input required id="address" type="text" class="form-control input nc-micro" style="width: 100%; color: #6e6e6e" />
                </div>

              </div>
              <div class="row">
                <div class="col-50" style="padding-right: 2px; width: 50%; padding-right: 4px">
                  <label [style]="'color:' + controller.titleColor" class="nc-small">Número<span style="color: red">*</span></label> <input required id="number" type="number" class="form-control input nc-micro" style="color: #6e6e6e" />
                </div>

                <div class="col-50" style="padding-left: 4px; width: 50%">
                  <label [style]="'color:' + controller.titleColor" class="nc-small">Bairro<span style="color: red">*</span></label> <input required id="district" type="text" class="form-control input nc-micro" style="color: #6e6e6e" />
                </div>
              </div>

              <div class="row" style="width: 100%; display: block">
                <div class="col-lg-12">
                  <label [style]="'color:' + controller.titleColor" class="nc-small">Complemento</label> <input type="text" id="complement"
                                                                                                                class="form-control input nc-micro" />
                </div>
              </div>
              <div class="row">

                <div class="col-lg-12" style="display: block; width: 100%">
                  <button type="button" (click)="confirmAddress()"
                          [style]="'background:' + controller.primaryColor + ' !important;' + 'color:white !important'"
                          class="form-control nc-micro">
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="hasAddress()">
              <div class="row" style="width: 100%;display:block">
                <div class="col-lg-12">
                  <label [style]="'color:' + controller.titleColor" class="nc-small">Nome<span style="color: red">*</span></label>
                  <input required id="name" type="text" class="form-control input nc-micro" style="width: 100%;color:#6e6e6e" />
                </div>
              </div>
              <div class="row">

                <div [class]="requirePix ? 'col-100' : 'col-50'" style="width: 50%; padding-right: 4px">
                  <label [style]="'color:' + controller.titleColor" class="nc-small">Número do celular<span style="color: red">*</span></label>
                  <input id="cellphone" type="text" required mask="(00) 00000-0000"
                         class="form-control input nc-micro" style="color: #6e6e6e" />
                </div>
                <div class="col-50" ng-show="!properties.requirePix" style="width: 50%; padding-left: 4px">
                  <label [style]="'color:' + controller.titleColor" class="nc-small">Tipo de pagamento<span style="color: red">*</span></label>
                  <select id="orderPaymentType" class="form-control nc-micro" required
                          (change)="changeOrderPaymentType($event)"
                          style="appearance: auto !important; height: 38px; color: #6e6e6e">
                    <option value="" selected>Selecione...</option>
                    <option value="CREDIT_CARD">Cartão de Crédito</option>
                    <option value="DEBIT_CARD" *ngIf="menu.paymentGateway != 'PAGBANK'">Cartão de Débito</option>
                    <option value="CASH">Dinheiro</option>
                    <option value="PIX" *ngIf="hasPixKey()">Pix</option>
                  </select>
                </div>
              </div>
              <div class="row" *ngIf="orderPaymentType == 'CASH'">


                <div class="col-50" style="width: 50%; padding-right: 4px"
                     ng-show="properties.orderPaymentType == 'CASH'"></div>
                <div class="col-50" style="width: 50%; padding-left: 4px">
                  <label [style]="'color:' + controller.titleColor" class="nc-small">Troco pra quanto?</label> <input id="change" type="text" currencyMask
                                                                                                                      class="form-control input currency nc-micro" placeholder="R$ 0,00" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" style="color: #6e6e6e" />
                </div>
              </div>

              <!--<div class="row" *ngIf="menu.bolEnabled">

    <div class="col-50" style="width: 50%; padding-right: 4px"
      [hidden]="orderPaymentType != 'CREDIT_CARD' && orderPaymentType != 'DEBIT_CARD'">
      <label [style]="'color:' + controller.titleColor" class="nc-small">Informe seu CPF</label> <input id="cpf" type="text" mask="000.000.000-00" required
                                                                                class="form-control input nc-micro"  style="color: #6e6e6e" />
     </div>
      <div class="col-50" style="width: 50%; padding-left: 4px"
      ng-show="properties.orderPaymentType == 'CREDIT_CARD' || properties.orderPaymentType == 'DEBIT_CARD'"></div>
  </div>-->

              <div class="row" id="cardFeeInfo" [style]="orderPaymentType == 'CREDIT_CARD' && menu.paymentGateway == 'PAGBANK' ? 'display:none' : 'display:block'">

                <div class="col-100" style="width: 100%; padding-right: 4px"
                     [hidden]="orderPaymentType != 'CREDIT_CARD'">
                  <label [style]="'color:' + controller.titleColor" class="nc-small">Taxa adicional da maquininha:</label> <b>{{_currencyService.formatNumber(getCreditCardFee())}}</b>
                </div>
                <div class="col-100" style="width: 100%; padding-right: 4px"
                     [hidden]="orderPaymentType != 'DEBIT_CARD'">
                  <label [style]="'color:' + controller.titleColor" class="nc-small">Taxa adicional da maquininha:</label> <b>{{_currencyService.formatNumber(getDebitCardFee())}}</b>
                </div>
              </div>
              <div class="row" style="display: none">
                <div class="col-100">
                  <input checked="checked" type="radio" id="entrega"
                         name="orderType" value="WEBAPP"
                         style="width: unset !important; display: inline-block; color: #6e6e6e">
                  <label for="entrega" class="nc-micro" [style]="'color:' + controller.titleColor">ENTREGA</label> <input type="radio" class="nc-micro"
                                                                                                                          id="retirada" name="orderType" value="TAKEOUT"
                                                                                                                          style="width: unset !important; display: inline-block; margin-left: 10px; color: #6e6e6e">
                  <label for="retirada" class="nc-miro" [style]="'color:' + controller.titleColor">RETIRADA</label>
                </div>
              </div>
              <div class="row" [style]="orderPaymentType == 'CREDIT_CARD' && menu.paymentGateway == 'PAGBANK' ? 'display:none' : 'display:block'">

                <div class="col-lg-12" style="display: block; width: 100%">
                  <button id="closeOrder" type="submit"
                          [style]="'background:' + controller.primaryColor + ' !important;' + 'color:white !important'"
                          class="form-control nc-micro">
                    {{
requirePix ?
									'Continuar' : 'Finalizar pedido'
                    }}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </form>
        <form id="pay" name="pay" (submit)="presentMessagePagseguro()" ngNativeValidate style="padding-bottom:25px"
              *ngIf="orderPaymentType == 'CREDIT_CARD' && menu.paymentGateway == 'PAGBANK'">
          <div class="row" style="display:block">
            <div class="col-lg-12">
              <label for="email">E-mail<span style="color: red">*</span></label>
              <input required type="email" id="email" name="email" placeholder="Seu e-mail" class="form-control input nc-micro" />
            </div>
          </div>
          <div class="row" style="display:block">
            <div class="col-lg-12">
              <label for="cardNumber">Número do cartão<span style="color: red">*</span></label>
              <input (keyup)="getCreditCardBrand($event)" required class="form-control input nc-micro" type="text" id="cardNumber" data-checkout="cardNumber" placeholder="0000 0000 0000 0000" mask="0000 0000 0000 0000" onselectstart="return false" onpaste="return false" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off />
              <div class="brand"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6" style="width:50%;padding-right:4px;float:left">
              <label for="cardExpirationMonth">Vencimento<span style="color: red">*</span></label>
              <input required type="text" id="cardExpiration" data-checkout="cardExpiration" placeholder="MM/AA" mask="00/00" onselectstart="return false" onpaste="return false" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off class="form-control input nc-micro" />
            </div>
            <div class="col-lg-6" style="width:50%;padding-left:4px;float:left">
              <label for="securityCode">{{cardCodeName}}<span style="color: red">*</span></label>
              <input required type="text" id="securityCode" data-checkout="securityCode" [placeholder]="cardCodeName" onselectstart="return false" onpaste="return false" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off class="form-control input nc-micro" />
            </div>
          </div>

          <div class="row" style="display:block">
            <div class="col-lg-12">
              <label for="cardholderName">Nome do titular<span style="color: red">*</span></label>
              <input required type="text" id="cardholderName" data-checkout="cardholderName" placeholder="Nome do titular do cartão"
                     class="form-control input nc-micro" />
            </div>
          </div>
          <div class="row" style="display:block">
            <div class="col-lg-12">
              <label for="docNumber">CPF ou CNPJ<span style="color: red">*</span></label>
              <input required type="text" id="docNumber" name="cpf" data-checkout="docNumber" placeholder="(somente números)" class="form-control input nc-micro" />
            </div>
          </div>
          <div class="row" style="display:block">
            <div class="col-lg-12">
              <button type="button" [disabled]="spin" required value="Finalizar pedido" style="color: white; background: rgb(234, 29, 44)" class="form-control nc-micro" (click)="validateFirstForm()" [style]="'background:' + controller.primaryColor + ' !important;' + 'color:white !important'">
                <i *ngIf="spin" class="fa fa-spinner fa-spin " style="margin-right: 5px"> </i><span >Finalizar pedido</span>
              </button>
              <input type="button" id="afterAddressBtn" style="display:none" onclick="validateFirstForm()" />
            </div>
          </div>
          <div class="row" style="display:none">
            <div class="col-lg-12">
              <input id="submit-pagseguro" required type="submit" value="Finalizar pedido" style="color: white !important; background: rgb(234, 29, 44) !important" class="form-control nc-micro"/>
            </div>
          </div>
        </form>
      </div>
      <div id="retirada-no-local" class="tabcontent" style="display: none">
        <form (submit)="presentMessage()" ngNativeValidate>

          <div class="row" style="width: 100%; display: block">
            <div class="col-lg-12">
              <label class="nc-small" [style]="'color:' + controller.titleColor">Nome<span style="color: red">*</span></label>
              <input required id="name2" type="text" class="form-control input nc-micro" style="width: 100%;color:#6e6e6e" />
            </div>
          </div>
          <div class="row" style="width: 100%; display: block">


            <div class="col-100">
              <label class="nc-small" [style]="'color:' + controller.titleColor">Número do celular<span style="color: red">*</span></label>
              <input id="cellphone2" type="text" required style="width: 100%;color:#6e6e6e"
                     class="form-control input nc-micro" mask="(00) 00000-0000" />
            </div>

          </div>
          <div class="row" style="display: none">
            <div class="col-100">
              <input type="radio" id="entrega" name="orderType" value="WEBAPP"
                     style="width: unset !important; display: inline-block;">
              <label for="entrega" class="nc-small" [style]="'color:' + controller.titleColor">ENTREGA</label> <input checked="checked" class="nc-micro"
                                                                                                                      type="radio" id="retirada" name="orderType" value="TAKEOUT"
                                                                                                                      style="width: unset !important; display: inline-block; margin-left: 10px;color:#6e6e6e">
              <label for="retirada" class="nc-small" [style]="'color:' + controller.titleColor">RETIRADA</label>
            </div>
          </div>

          <div [style]="'background: rgb(252,187,0,0.1); color: #7D6D3E; border-radius: 10px; padding: 15px; margin-bottom: 15px; display:' + (menu.address != null && menu.address != undefined && menu.address.trim() != '' ? 'flex' : 'none')" class="nc-micro">
            <div style="display:block;float:left">
              <svg xmlns="http://www.w3.org/2000/svg" style="margin-top:10px" height="16" width="10" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M208 96c26.5 0 48-21.5 48-48S234.5 0 208 0s-48 21.5-48 48 21.5 48 48 48zm94.5 149.1l-23.3-11.8-9.7-29.4c-14.7-44.6-55.7-75.8-102.2-75.9-36-.1-55.9 10.1-93.3 25.2-21.6 8.7-39.3 25.2-49.7 46.2L17.6 213c-7.8 15.8-1.5 35 14.2 42.9 15.6 7.9 34.6 1.5 42.5-14.3L81 228c3.5-7 9.3-12.5 16.5-15.4l26.8-10.8-15.2 60.7c-5.2 20.8 .4 42.9 14.9 58.8l59.9 65.4c7.2 7.9 12.3 17.4 14.9 27.7l18.3 73.3c4.3 17.1 21.7 27.6 38.8 23.3 17.1-4.3 27.6-21.7 23.3-38.8l-22.2-89c-2.6-10.3-7.7-19.9-14.9-27.7l-45.5-49.7 17.2-68.7 5.5 16.5c5.3 16.1 16.7 29.4 31.7 37l23.3 11.8c15.6 7.9 34.6 1.5 42.5-14.3 7.7-15.7 1.4-35.1-14.3-43zM73.6 385.8c-3.2 8.1-8 15.4-14.2 21.5l-50 50.1c-12.5 12.5-12.5 32.8 0 45.3s32.7 12.5 45.2 0l59.4-59.4c6.1-6.1 10.9-13.4 14.2-21.5l13.5-33.8c-55.3-60.3-38.7-41.8-47.4-53.7l-20.7 51.5z" /></svg>
            </div>
            <div style="display:block;float:left;margin-left:15px">
              <b>Local de retirada</b><br />
              {{menu.address}}
            </div>
          </div>

          <div class="row">
            <div class="col-lg-10">
            </div>
            <div class="col-lg-2">
              <button id="closeOrder" type="submit"
                      [style]="'background:' + controller.primaryColor + ' !important;' + 'color:white !important'"
                      class="form-control nc-micro">
                Finalizar pedido
              </button>
            </div>
          </div>

        </form>
      </div>
      <div id="personalizada" class="tabcontent" style="display: none">
        <form (submit)="presentMessage()" ngNativeValidate>

          <div class="row" style="width: 100%; display: block">
            <div class="col-100">
              <label class="nc-small" [style]="'color:' + controller.titleColor">Nome<span style="color: red">*</span></label>
              <input required id="name3" type="text" class="form-control input nc-micro" style="width: 100%;color:#6e6e6e" />
            </div>
          </div>
          <div class="row">


            <div class="col-50" ng-show="!properties.requirePix" style="width: 50%; padding-right: 4px">
              <label class="nc-small" [style]="'color:' + controller.titleColor">Tipo de pagamento<span style="color: red">*</span></label>
              <select id="orderPaymentType3" required class="form-control nc-micro" [style]="'color:' + controller.titleColor"
                      style="appearance: auto !important; height: 38px;"
                      (change)="changeOrderPaymentType($event)">
                <option value="" selected>Selecione...</option>
                <option value="CREDIT_CARD">Cartão de Crédito</option>
                <option value="DEBIT_CARD">Cartão de Débito</option>
                <option value="CASH">Dinheiro</option>
                <option value="PIX" *ngIf="hasPixKey()">Pix</option>
              </select>
            </div>

            <div [class]="requirePix ? 'col-100' : 'col-50'" style="width: 50%; padding-left: 4px">
              <label class="nc-small" [style]="'color:' + controller.titleColor">Número do celular<span style="color: red">*</span></label>
              <input id="cellphone3" type="text" required
                     class="form-control input nc-micro" mask="(00) 00000-0000" style="color:#6e6e6e" />
            </div>


          </div>

          <div class="row">

            <div class="col-50"
                 [hidden]="orderPaymentType != 'CASH'">
              <label class="nc-small" [style]="'color:' + controller.titleColor">Troco pra quanto?</label> <input id="change_" type="text" currencyMask placeholder="R$ 0,00" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
                                                                                                      class="form-control nc-micro" style="color:#6e6e6e" />
            </div>
            <div class="col-50"
                 [hidden]="orderPaymentType == 'CASH'"></div>
          </div>

          <div class="row">
            <div class="col-50"
                 *ngFor="let obj of controller.customDeliveryObjects; let i = index">
              <label>
                <span [id]="'customDeliveryTitle' + i"
                      [innerHTML]="obj.title" class="nc-small"></span> <span style="color: red">*</span>
              </label> <select [id]="'customDeliverySelect' + i" required [style]="'color:' + controller.titleColor"
                               class="form-control customDeliverySelect nc-micro"
                               style="appearance: auto !important"
                               (change)="onSelectCustomDelivery()">
                <option value="" class="nc-small">Selecione...</option>
                <option *ngFor="let option of obj.optionsArr"
                        [innerHTML]="option" [value]="option" class="nc-micro"></option>
              </select>
            </div>

          </div>
          <div class="row">
            <div class="col-lg-10">
            </div>
            <div class="col-lg-2">
              <button id="closeOrder" type="submit"
                      [style]="'background:' + controller.primaryColor + ' !important;' + 'color:white !important'"
                      class="form-control nc-micro">
                Finalizar pedido
              </button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

  <div class="bottom-block">
    <div id="bottom-block-black" class="bottom-block-black"
         (click)="bottomBlockBlack()"></div>
    <div id="bottom-block-content" class="bottom-block-content animation-fix">
      <div style="display: block; float: left; width: 100%; text-align: center">
        <h4 style="margin-top: 15px" class="nc-small-bold" [style]="'color:' + controller.titleColor">Valor mínimo</h4>
        <p class="bottom-block-p nc-micro"
           style="margin: 0; margin-top: 15px; margin-bottom: 25px; text-align: left; font-size: 9pt; text-align: center" [style]="'color:' + controller.titleColor">
          O
          pedido mínimo desse restaurante é
          {{formatNumber(controller.minimumOrder)}}, sem contar a taxa de
          entrega.
        </p>

      </div>

      <div style="display: block; float: right; width: 100%; margin-bottom: 25px">
        <button class="button waves-effect waves-light nc-micro"
                (click)="gotoMenu()"
                [style]="'background:' + controller.primaryColor + ' !important; height: 40px; width: 100%; margin-top: 0; padding-left: 15px; padding-right: 15px'">
          Adicionar mais itens
        </button>
      </div>

      <span [style]="'margin-left:auto;margin-right:auto;color:' + controller.primaryColor" class="bottom-block-a nc-micro"
            (click)="linkClick()">
        Cancelar
      </span>
    </div>
  </div>

  <div id="bottom-block" class="bottom-block" style="display: none">

    <div id="bottom-block-content" class="bottom-block-content"
         style="display: block">
      <div style="display: block; float: left; width: 50%">
        <p class="bottom-block-p nc-micro"
           style="margin: 0; text-align: left; font-size: 9pt" [style]="'color:' + controller.titleColor + ' !important'">
          Total com a entrega
        </p>
        <p class="bottom-block-p"
           style="margin: 0; text-align: left; font-size: 11pt" [style]="'color:' + controller.titleColor + ' !important'">
          <b><span id="total-bottom" class="nc-micro-bold"></span> / </b><span id="itens" class="nc-micro"></span>
        </p>
      </div>
      <div style="display: block; float: right; width: 50%">

        <button (click)="openShowDialog()" mat-button class="nc-button waves-effect waves-light nc-small" [style]="'background:' + controller.primaryColor + ' !important; height: 40px; width: 100%; margin-top: 0; padding-left: 15px; padding-right: 15px'">

          <span class="nc-micro" style="color:white">Continuar</span>
        </button>
      </div>
    </div>
  </div>

</div>
<button id="getDeliveryFeeMoney" (click)="getDeliveryFeeMoney()" style="display:none"></button>
<button id="confirmBtn" (click)="confirm()" style="display:none"></button>
<button id="cardErrorBtn" (click)="cardError()" style="display:none"></button>
<button id="pagseguroBtn" onclick="pagseguro()" style="display:none"></button>
<button id="pagseguroSpinBtn" (click)="spin = true" style="display:none"></button>
