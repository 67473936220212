import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { Menu } from '../dto/Menu';
import { CurrencyService } from '../services/currency.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ai-agent-chat',
  templateUrl: './ai-agent-chat.component.html',
  styleUrls: ['./ai-agent-chat.component.css']
})
export class AiAgentChatComponent {

  controller: any = "";

  menu: Menu = new Menu();

  menuConfig: any = new Object();

  districtResponseYes: string = "Sim, entregamos neste bairro.";

  districtResponseNo: string = "Desculpe, mas não entregamos neste bairro.";

  messages: Map<string, boolean> = new Map();

  notUnderstoodMessagesArr: Array<string> = ["Lamento, mas não compreendi.", "Desculpe, mas não compreendi.",
    "Infelizmente, não captei o que deseja.", "Não consegui compreender, desculpe."];

  constructor(private httpClient: HttpClient, private globalService: GlobalService,
    private currencyService: CurrencyService, private snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    
    let that = this;

    let storename = localStorage.getItem("storename");

    that.httpClient.get(that.globalService.url + "/mm?loja=" + storename).subscribe((response) => {

      that.controller = response;

    });

    that.httpClient.get(that.globalService.url + "/get-menu-by-name?name=" + storename).subscribe((response) => {

      that.menu = <Menu>response;

    });

    that.httpClient.get(that.globalService.url + "/get-wa-config-by-menu-name?name=" + storename).subscribe((response) => {

      that.menuConfig = response;

    });

    let dayBefore: any = localStorage.getItem("dayBefore");
    
    if (dayBefore == null || dayBefore == undefined || dayBefore.trim() == "") {

      dayBefore = new Date();

      localStorage.setItem("dayBefore", dayBefore);

      localStorage.setItem("next-promo-id", "0");

    } else {
      
      dayBefore = new Date((<number><unknown>localStorage.getItem("dayBefore")));

    }

    if (this.sameDay(dayBefore, new Date())) {
      
      this.fillChat();

      let order = JSON.parse(<string>localStorage.getItem("lastOrderCart"));

      that.httpClient.get(that.globalService.url + '/get-order-by-id-entity?orderId=' + order.id).subscribe((response) => {

        let message = "";

        if ((<any>response)["orderStatus"] == "ORDER_RECEIVED") {

          let pixLink: string = (<any>response)["pixLink"];

          if (pixLink != null && pixLink != undefined && pixLink.trim() != "") {

            message = "O seu pedido ainda aguarda a confirmação do pagamento. <a onclick='copyPixLink(\"" +
              pixLink + "\", true)' style='font-weight:bold;text-decoration:underline'>Clique aqui</a> e copie o código do Pix Copia e Cola.";

          } else {

            message = "O seu pedido ainda não está em produção. <a href='https://api.whatsapp.com/send?phone=55" +
              this.controller.whatsapp + "&text=' target='_blank' style='text-decoration:underline;font-weight:bold'>Clique aqui</a> caso deseje falar com um atendente humano.";

          }

        } else if ((<any>response)["orderStatus"] == "PREPARING_THE_FOOD") {

          if (this.menuConfig == null || this.menuConfig == undefined || this.menuConfig["deliveryTime"] == null ||
            this.menuConfig["deliveryTime"] == undefined) {

            return;

          }

          let minutes = <number>this.menuConfig["deliveryTime"];

          message = "O seu pedido N° " + order.orderNumber + " se encontra em produção. Tempo estimado para entrega: " +
            minutes + " à " + (minutes + 10) + " minutos.";

        } else if ((<any>response)["orderStatus"] == "OUT_FOR_DELIVERY") {

          message = "O seu pedido já saiu para entrega e logo chegará no seu local.";

        };

        if (message.trim() != "") {
          setTimeout(function () {

            that.appendMessage(message, true);

          }, 1500)

        }

      });

    } else {

      dayBefore = new Date();

      localStorage.setItem("dayBefore", dayBefore);

      localStorage.setItem("chat-messages", "");

      this.fillChat();

      localStorage.setItem("next-promo-id", "0");

    }

  }

  fillChat() {

    let that = this;

      let messages = localStorage.getItem("chat-messages");

      if (messages == null || messages == undefined || messages.trim() == "") {

        setTimeout(function () {

          let date = new Date();

          if (date.getHours() < 12 && date.getHours() > 3) {

            that.appendMessage("Olá, bom dia!", true);
            localStorage.setItem("welcome-phrase", "Bom dia!");

          } else if (date.getHours() < 18 && date.getHours() > 3) {

            that.appendMessage("Olá, boa tarde!", true);
            localStorage.setItem("welcome-phrase", "Boa tarde!");

          } else {

            that.appendMessage("Olá, boa noite!", true);
            localStorage.setItem("welcome-phrase", "Boa noite!");

          }
          setTimeout(function () {

            that.appendMessage("Bem-vindo! Este é o nosso autoatendimento! 🤖 Como posso ajudar?", true)

          }, 500)

        }, 500);

      } else {

        setTimeout(function () {

          let obj = JSON.parse(<string>messages);

          that.messages = new Map(Object.entries(obj));

          that.messages.forEach((k, v) => {

            if (k) {
              that.appendOwnerMessage(v.split("###")[0], false);
            } else {
              that.appendMessage(v.split("###")[0], false);
            }

          })

        }, 1);

      }

  }

  sameDay(d1: Date, d2: Date) {

    return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

  }

  askQuestionInput() {

    let that = this;

    let text = (<any>document.getElementById("inputField"))["value"];

    that.appendOwnerMessage(text, true);

    (<any>document.getElementById("inputField"))["value"] = "";

    let deliveryFeeQuestion = localStorage.getItem("deliveryFeeQuestion");
    
    if (deliveryFeeQuestion != null && deliveryFeeQuestion != undefined && deliveryFeeQuestion.trim() != "" &&
      deliveryFeeQuestion.trim() == "true") {

      return that.getDeliveryFeeByDistrictMessage(text);

    }

    that.askQuestion(text);

  }

  askQuestion(text: string) {

    let that = this;

    let body = JSON.stringify({ "text": text });
    
    fetch(that.globalService.nodejsUrl + "/ask", {
      method: 'POST', headers: new Headers({ 'Content-Type': 'application/json; charset=utf-8' }), body: body
    }).then(response => {

      if (response.ok) {

        response.json().then(json => {

          setTimeout(function () {

            switch (json.split("###")[0]) {
              case "ola":
                that.appendMessage("Oi. Bora pedir hoje?", true);
                break;
              case "qual_o_endereco":

                if (that.controller.address != null && that.controller.address != undefined &&
                  that.controller.address.trim() != "") {
                    
                  that.appendMessage("Para retirar você pode vir neste endereço: <b>" + that.controller.address + "</b>.", true);

                } else {
                  
                  that.appendRandomMessage();

                }

                break;
              case "quanto_custa":
                that.appendMessage("Dê uma olhadinha no nosso cardápio. Tenho certeza que você vai gostar! 🥰", true)
                break;
              case "faz_entrega":
                that.appendMessage("Sim, trabalhamos com entrega.", true)
                break;
              case "quais_os_bairros":
                that.getDistrictsMessage();
                break;
              case "entregam_no_bairro_x":
                that.getYesOrNoForDistrict(json.split("###")[1]);
                break;
              case "qual_o_valor_da_entrega":
                that.getDeliveryFeeMessage();
                break;
              case "tem_taxa_de_entrega":
              case "taxa_de_entrega_no_bairro_x":
                that.getYesOrNoForSpecificDistrict(json.split("###")[1]);
                break;
              case "quero_o_cardapio":
                that.appendMessage("Temos ótimos preços hoje! É só dar uma olhadinha no nosso cardápio e fazer o seu pedido! 😊", true);
                break;
              case "qual_o_pedido_minimo":
                that.getMinimumOrderMessage();
                break;
              case "quanto_demora":
                that.getTimeForDeliveringMessage();
                break;
              case "estao_abertos":
                that.getOpenedMessage();
                break;
              case "tem_promocao":
              case "tem_algum_outro":
                that.getPromotionsMessage();
                break;
              case "qual_o_mais_pedido":
                that.getBestsellerMessage();
                break;
              case "que_horas_voces_fecham":
                that.getClosedMessage();
                break;
              case "tem_desconto":
                that.getDiscountMessage();
                break;
              case "parte_um_parte_outro_pagamento":
                that.getHalfPaymentMessage();
                break;
              case "qual_a_chave_pix":
                that.getPixMessage();
                break;
              case "qual_o_horario_de_atendimento":
                that.getOpeningHoursMessage();
                break;
              case "problema_com_a_entrega":
                that.getDeliveryProblemMessage();
                break;
              case "obrigado":
                that.appendMessage("De nada!", true);
                break;
              case "meios_de_pagamento_promocao":
                that.appendMessage("Sim, aceitamos pagamento no pix, cartão ou dinheiro.", true);
                break;
              case "quais_as_formas_de_pagamento":
                that.appendMessage("Aceitamos pagamento no pix, cartão ou dinheiro.", true);
                break;
              case "cancelar_pedido":
                that.appendMessage("Gostaria mesmo de cancelar o último pedido?", true);
                localStorage.setItem("agent-action", "cancel");
                break;
              case "sim":
                if (localStorage.getItem("agent-action") == "cancel") {
                  that.cancelLastOrder();
                }
                break;
              case "nao":
                if (localStorage.getItem("agent-action") == "cancel") {
                  that.appendMessage("Ok, não irei cancelar o pedido.", true)
                }
                break;
              case "bom_dia":
              case "boa_tarde":
              case "boa_noite":
                that.appendMessage(localStorage.getItem("welcome-phrase") + " Este é o nosso autoatendimento! Como posso ajudar?", true);
                break;
              default:
                let msg = json.split("###")[2];

                if (msg != null && msg != undefined && msg.trim() != "") {
                  that.appendMessage(msg, true);
                }
            };

          }, 1000)

        })

      }

    })

  }

  appendOwnerMessage(message: any, save: boolean) {

    if (save) {

      this.messages.set(message + "###" + this.messages.size, true);

      localStorage.setItem("chat-messages", JSON.stringify(this.messages));

    }

    let htmlStr = '<div class="chat-msg owner" style="flex-direction: row-reverse;display: flex;padding: 0 0px 20px;"><div class="chat-msg-content" style="margin-left: 0;margin-right: 12px;align-items: flex-end;max-width: 70%;display: flex;flex-direction: column; "><div class="chat-msg-text" style="background-color: #0084ff;color: #fff!important;border-radius: 20px 20px 0 20px;padding: 15px;line-height: 1.5;font-size: 14px;font-weight: 500;">' + message + '</div></div></div>';

    let div = this.createElementFromHTML(htmlStr);

    let chatAreaMain = document.getElementsByClassName("chat-area-main")[0];

    chatAreaMain.append(div!);

    chatAreaMain.scrollTop = chatAreaMain.scrollHeight;

  }

  appendMessage(message: any, save: boolean) {

    if (save) {

      this.messages.set(message + "###" + this.messages.size, false);

      const obj = Object.fromEntries(this.messages);

      localStorage.setItem("chat-messages", JSON.stringify(obj));

    }

    let htmlStr = '';

    if (message.includes("data:image")) {

      htmlStr = '<div style="display:flex;padding:0 0px 20px;max-width:70%;margin-left:12px"><a href="/#/p?loja=' + this.menu.name + '&prodId=' + message.split("#")[1] + '"><img style="display:flex;max-width:100%;border-radius:20px" src="' + message.split("#")[0] + '"></a></div>';
      
    } else {

     htmlStr = '<div class="chat-msg" style="display: flex;padding: 0 0px 20px;"><div class="chat-msg-content" style="margin-left: 12px;max-width: 70%;display: flex;flex-direction: column;align-items: flex-start;"><div class="chat-msg-text" style="background-color: #f5f6f7;color: #0e0e0e!important;border-radius: 20px 20px 0 20px;padding: 15px;line-height: 1.5;font-size: 14px;font-weight: 500;">' + message + '</div></div></div>';

    }

    let div = this.createElementFromHTML(htmlStr);

    let chatAreaMain = document.getElementsByClassName("chat-area-main")[0];

    chatAreaMain.append(div!);

    chatAreaMain.scrollTop = chatAreaMain.scrollHeight;

    let chatArea = document.getElementById("chat-area");
    let chatAreaHeader = document.getElementById("chat-area-header");
    let chatAreaFooter = document.getElementById("chat-area-footer");

    let headerY = chatAreaHeader?.getBoundingClientRect().y;
    let headerHeight = chatAreaHeader?.getBoundingClientRect().height;
    headerY = headerY! + headerHeight!;

    let footerY = chatAreaFooter?.getBoundingClientRect().y;

    let height = footerY! - headerY;

    $("#chat-area-main").css("height", height + "px");
    $("#chat-area-main").css("max-height", height + "px");

  }

  createElementFromHTML(htmlString:any) {

    const chatMsgOwner = document.createElement('div');

    // make a new parser
    const parser = new DOMParser();

    // convert html string into DOM
    const html = parser.parseFromString(htmlString, "text/html");

    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;

  }

  getDistrictsMessage() {

    if (this.menu.districtsDeliveryFeeObjects == null || this.menu.districtsDeliveryFeeObjects == undefined ||
      this.menu.districtsDeliveryFeeObjects.trim() == "") {

      return this.appendMessage(this.districtResponseYes, true);

    }

    let arr = JSON.parse(this.menu.districtsDeliveryFeeObjects);

    let str = "Atendemos nos seguintes bairros: <b>";
    
    for (let i = 0; i < arr.length; i++) {

      if (i == arr.length - 1) {

        str += " e " + (<any>arr[i])["district"];

      } else if (i == 0) {

        str += (<any>arr[i])["district"];

      } else {

        str += ", " + (<any>arr[i])["district"];

      }

    }

    str += "</b>.";

    this.appendMessage(str, true);

  }

  getYesOrNoForDistrict(district: string) {

    if (this.menu.districtsDeliveryFeeObjects == null || this.menu.districtsDeliveryFeeObjects == undefined ||
      this.menu.districtsDeliveryFeeObjects.trim() == "") {

      return this.appendMessage(this.districtResponseYes, true);

    }

    let arr = JSON.parse(this.menu.districtsDeliveryFeeObjects);

    for (let i = 0; i < arr.length; i++) {

      if ((<any>arr[i])["district"].toLowerCase() == district.replace("?", "").toLowerCase()) {

        return this.appendMessage("Sim, atendemos no bairro <b>" + (<any>arr[i])["district"] +
          "</b>. A taxa de entrega para este bairro é de <b>" +
          this.currencyService.formatNumber((<any>arr[i])["price"]) + "</b>.", true);

      }

    }

    return this.appendMessage(this.districtResponseNo, true);

  }

  getYesOrNoForSpecificDistrict(district: string) {

    if (this.menu.districtsDeliveryFeeObjects == null || this.menu.districtsDeliveryFeeObjects == undefined ||
      this.menu.districtsDeliveryFeeObjects.trim() == "") {

      this.appendRandomMessage();

      return;

    }
    
    let arr = JSON.parse(this.menu.districtsDeliveryFeeObjects);

    for (let i = 0; i < arr.length; i++) {

      if ((<any>arr[i])["district"].toLowerCase() == district.replace("?", "").toLowerCase()) {

        this.appendMessage("A taxa de entrega para este bairro é de <b>" +
          this.currencyService.formatNumber((<any>arr[i])["price"]) + "</b>.", true);

      }

    }

    if (this.menuConfig != null && this.menuConfig != undefined && this.menuConfig["deliveryFeeMoney"] != null &&
      this.menuConfig["deliveryFeeMoney"] != undefined) {

      this.appendMessage("A nossa taxa de entrega padrão é de <b>" +
        this.currencyService.formatNumber(this.menuConfig["deliveryFeeMoney"]) + "</b>.", true);

    } else {

      this.appendRandomMessage();

      return;

    }

  }

  getMinimumOrderMessage() {

    if (this.menuConfig != null && this.menuConfig != undefined && this.menuConfig["minimumOrder"] != null &&
      this.menuConfig["minimumOrder"] != undefined) {

      this.appendMessage("O valor mínimo para pedidos é de <b>" +
        this.currencyService.formatNumber(this.menuConfig["minimumOrder"]) + "</b>.", true);

    } else {

      this.appendMessage("Não temos um valor mínimo definido. Fique à vontade para pedir o quanto quiser! 😊", true);

      return;

    }

  }

  getTimeForDeliveringMessage() {

    if (this.menuConfig == null || this.menuConfig == undefined || this.menuConfig["deliveryTime"] == null ||
      this.menuConfig["deliveryTime"] == undefined) {

      this.appendRandomMessage();

      return;

    }

    let minutes = <number>this.menuConfig["deliveryTime"];

    this.appendMessage("O nosso tempo de entrega é de <b>" + minutes + " à " + (minutes + 10) + " minutos</b>.", true);

  }

  getOpenedMessage() {

    if (this.menu.opened) {

      this.appendMessage("Já estamos abertos. Pode dar uma olhadinha no cardápio e fazer o seu pedido.", true);

      return;

    } else if (this.menu.availabilityJson == null || this.menu.availabilityJson == undefined ||
      this.menu.availabilityJson.trim() == "") {

      this.appendRandomMessage();

      return;

    }

    const days = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];

    let now = new Date();

    var day = days[now.getDay()];

    let availabilityJson = JSON.parse(this.menu.availabilityJson);

    if (availabilityJson.hasOwnProperty(day)) {

      let openTime: string = availabilityJson[day].split(",")[0];

      let hours = openTime.split(":")[0];
      let minutes = openTime.split(":")[1];

      hours = hours.charAt(0) == '0' ? hours.substring(1, hours.length) : hours;
      minutes = minutes.charAt(0) == '0' ? minutes.substring(1, minutes.length) : minutes;

      let openTimeDate = new Date();
      openTimeDate.setHours(<number><unknown>hours);
      openTimeDate.setMinutes(<number><unknown>minutes);

      if (now.getTime() < openTimeDate.getTime()) {

        this.appendMessage("Estaremos abertos hoje às " + openTime + "hs.", true);

      } else {

        this.appendMessage("Aguarde só uns minutinhos, já já estaremos abertos.", true);

      }

    } else {

      this.appendMessage("Desculpe, não iremos abrir hoje.", true);

    };

  }

  getOpeningHoursMessage() {

    if (this.menu.availabilityJson == null || this.menu.availabilityJson == undefined ||
      this.menu.availabilityJson.trim() == "" || this.menu.availabilityJson.trim() == "{}") {

      this.appendRandomMessage();

      return;

    }

    const days = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];

    let now = new Date();

    var day = days[now.getDay()];

    let availabilityJson = JSON.parse(this.menu.availabilityJson);

    if (availabilityJson.hasOwnProperty(day)) {

      let openTime: string = availabilityJson[day].split(",")[0];
      let closeTime: string = availabilityJson[day].split(",")[1];

      this.appendMessage("Estaremos abertos hoje das " + openTime + "hs até às " + closeTime + "hs.", true);

    } else {

      this.appendMessage("Desculpe, não iremos abrir hoje.", true);

    }

  }

  getDiscountMessage() {

    let discount = this.menuConfig.discountsMoney;

    if (discount != null && discount != undefined && discount > 0) {

      this.appendMessage("Estamos com um desconto de <b>" + this.currencyService.formatNumber(discount) +
      "</b> hoje. Bora pedir?", true);

    } else {

      this.appendMessage("Desculpe, mas não estamos oferecendo nenhum desconto hoje.", true)

    }

  }

  getPixMessage() {
    
    let that = this;

    let order = JSON.parse(<string>localStorage.getItem("lastOrderCart"));

    that.httpClient.get(that.globalService.url + '/get-order-by-id-entity?orderId=' + order.id).subscribe((response) => {

      let message = "";

      if ((<any>response)["orderStatus"] == "ORDER_RECEIVED" && order.orderPaymentType == "PIX") {

        let pixLink: string = (<any>response)["pixLink"];

        if (pixLink != null && pixLink != undefined && pixLink.trim() != "") {

          message = "Para realizar o pagamento do seu pedido e liberar para o preparo <a onclick='copyPixLink(\"" +
            pixLink + "\", true)' style='font-weight:bold;text-decoration:underline'>Clique aqui</a> e copie o código Pix.";

        } else {

          message = "A chave para pagamentos no Pix é " + that.menu.pixKey + ". <a onclick='copyPixLink(\"" +
            that.menu.pixKey + "\", false)' style='text-decoration:underline;font-weight:bold'>Clique aqui</a> para copiá-la.";

        }

      }

      this.appendMessage(message, true);

    })

  }

  getClosedMessage() {
    
    if (this.menu.availabilityJson == null || this.menu.availabilityJson == undefined ||
      this.menu.availabilityJson.trim() == "") {

      this.appendRandomMessage();

      return;

    }

    const days = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];

    let now = new Date();

    var day = days[now.getDay()];

    let availabilityJson = JSON.parse(this.menu.availabilityJson);

    if (availabilityJson.hasOwnProperty(day)) {

      let closeTime: string = availabilityJson[day].split(",")[1];

      let hours = closeTime.split(":")[0];
      let minutes = closeTime.split(":")[1];

      hours = hours.charAt(0) == '0' ? hours.substring(1, hours.length) : hours;
      minutes = minutes.charAt(0) == '0' ? minutes.substring(1, minutes.length) : minutes;

      let closeTimeDate = new Date();
      closeTimeDate.setHours(<number><unknown>hours);
      closeTimeDate.setMinutes(<number><unknown>minutes);

      this.appendMessage("Fecharemos hoje às " + closeTime + "hs.", true);

    } else {

      this.appendMessage("Desculpe, não iremos abrir hoje.", true);

    };

  }

  cancelLastOrder() {

    let that = this;

    let order = JSON.parse(<string>localStorage.getItem("lastOrderCart"));

    that.httpClient.post(that.globalService.url + '/update-order-status?orderId=' + order.id + '&orderStatus=CANCELLED&deliverymanId=null&cancelationReason=&autoprint=true', null).subscribe((response) => {

      if (response == 'success') {

        that.appendMessage("Pedido cancelado.", true);

      }

    });

  }

  appendRandomMessage() {

    let i = Math.floor(Math.random() * 5);

    this.appendMessage(this.notUnderstoodMessagesArr[i], true);

  }

  getPromotionsMessage() {

    let that = this;

    let storename = localStorage.getItem("storename");

    //that.httpClient.get(that.globalService.url + "/mm?loja=" + storename).subscribe((response) => {

      //that.controller = response;

      let categories = new Array();

      let foods = new Array();

      for (let i = 0; i < that.controller.categories.length; i++) {

        let category = that.controller.categories[i];

        let categoryName = category.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        if (categoryName.includes("promocao") || categoryName.includes("promocoes")) {
          
          that.httpClient.get(that.globalService.url + "/get-foods-by-category-id-short?id=" + category.id).subscribe((response) => {

            let foodsArr = <Array<Object>>response;
            
            for (let food of foodsArr) {

              that.httpClient.get(that.globalService.url + "/find-count-by-account-id-and-food-code?id=" + (<any>food)["id"]).subscribe((response) => {

                let count: number = <number><unknown>response;

                (<any>food)["count"] = count == null ? 0 : count;

                foods.push(food);

              })

            }

          })

        }

      }

      setTimeout(function () {

        if (foods.length == 0) {

          that.appendMessage("Desculpe, não temos nenhuma promoção hoje. 😕", true);

        } else {

          foods.sort(that.compareCount);

          let nextPromoId = <number><unknown>localStorage.getItem("next-promo-id");
          nextPromoId = nextPromoId == null || nextPromoId == undefined ? 0 : <number><unknown>nextPromoId;

          if (nextPromoId >= foods.length) {

            return that.appendMessage("Desculpe, não temos mais nenhuma promoção hoje. 😕", true);

          }

          let first = foods[nextPromoId];

          localStorage.setItem("next-promo-id", (++nextPromoId).toString());

          that.appendMessage("Hoje temos <b>" + first.name + "</b> por apenas <b>" +
            that.currencyService.formatNumber(first.price) + "</b>. Clique na imagem para pedir.", true);

          that.httpClient.get(that.globalService.url + "/get-food-image?id=" + first.id).subscribe((response) => {

            let base64Image: string = <string>response;

            if (base64Image != null && base64Image != undefined && base64Image.trim() != "") {
              setTimeout(function () {

                that.appendMessage(response + "#" + first.id, true);

              }, 500)

            }

          });

        }

      }, 3000)

    //});

  }

  compareCount(a: any, b: any) {

    if (a.count < b.count) {
      return 1;
    }

    if (a.count > b.count) {
      return -1;
    }

    return 0;

  }

  getDeliveryProblemMessage() {

    let message = "Para resolver problemas com a entrega de nossos produtos, <a href='https://api.whatsapp.com/send?phone=55" +
      this.controller.whatsapp + "&text=' target='_blank' style='text-decoration:underline;font-weight:bold'>clique aqui</a> e fale com um de nossos atendentes.";

    this.appendMessage(message, true);

  }

  getHalfPaymentMessage() {

    let message = "Para maiores informações sobre pagamentos, <a href='https://api.whatsapp.com/send?phone=55" +
      this.controller.whatsapp + "&text=' target='_blank' style='text-decoration:underline;font-weight:bold'>clique aqui</a> e fale com um de nossos atendentes.";

    this.appendMessage(message, true);

  }

  getDeliveryFeeMessage() {
    
      if (this.menu.districtsDeliveryFeeObjects == null || this.menu.districtsDeliveryFeeObjects == undefined ||
        this.menu.districtsDeliveryFeeObjects.trim() == "") {
        alert(this.menuConfig.deliveryFeeMoney)
        if (this.menuConfig != null && this.menuConfig != undefined && this.menuConfig.deliveryFeeMoney != null
          && this.menuConfig.deliveryFeeMoney != undefined && this.menuConfig.deliveryFeeMoney > 0) {

          return this.appendMessage("A nossa taxa de entrega padrão é de <b>" +
            this.currencyService.formatNumber(this.menuConfig.deliveryFeeMoney) + "</b>.", true);

        }

        return this.appendMessage("Estamos oferecendo taxa de entrega grátis! Aproveite! 😊", true);

      }

    localStorage.setItem("deliveryFeeQuestion", "true");

    this.appendMessage("Qual o nome do seu bairro?", true);

  }

  getDeliveryFeeByDistrictMessage(district: string) {

    let that = this;
    setTimeout(function () { 

      localStorage.setItem("deliveryFeeQuestion", "false");

      let arr = JSON.parse(that.menu.districtsDeliveryFeeObjects);

        for (let i = 0; i < arr.length; i++) {

          if ((<any>arr[i])["district"].toLowerCase() == district.replace("?", "").toLowerCase()) {

            return that.appendMessage("A taxa de entrega para o bairro " + district + " é de <b>" +
              that.currencyService.formatNumber((<any>arr[i])["price"]) + "</b>.", true);

          }

        }

        return that.appendMessage("Desculpe, não atendemos neste bairro.", true);

    }, 2000)

  }

  getBestsellerMessage() {

    let that = this;

    let foods = new Array();

    for (let i = 0; i < that.controller.categories.length; i++) {

      let category = that.controller.categories[i];

        that.httpClient.get(that.globalService.url + "/get-foods-by-category-id-short?id=" + category.id).subscribe((response) => {

          let foodsArr = <Array<Object>>response;

          for (let food of foodsArr) {

            that.httpClient.get(that.globalService.url + "/find-count-by-account-id-and-food-code?id=" + (<any>food)["id"]).subscribe((response) => {

              let count: number = <number><unknown>response;

              (<any>food)["count"] = count == null ? 0 : count;

              foods.push(food);

            })

          }

        })

    }

    setTimeout(function () {

      foods.sort(that.compareCount);

      let first = foods[0];

      that.appendMessage("O nosso mais pedido é o(a) <b>" + first.name + "</b> por apenas <b>" +
      that.currencyService.formatNumber(first.price) + "</b>. Aproveite!", true);

      that.httpClient.get(that.globalService.url + "/get-food-image?id=" + first.id).subscribe((response) => {

        let base64Image: string = <string>response;

        if (base64Image != null && base64Image != undefined && base64Image.trim() != "") {

          setTimeout(function () { 

            that.appendMessage(response, true);

          }, 500)

        }

      });

    }, 3000)

  }

  copyCodeMessage() {

    this.snackBar.open('Código Pix copiado com sucesso', '', {
      duration: this.globalService.snackBarDuration
    });

  }

  copyKeyMessage() {

    this.snackBar.open('Chave Pix copiada com sucesso', '', {
      duration: this.globalService.snackBarDuration
    });

  }

}
