import { Component, ElementRef, OnInit } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../services/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-store-profile',
  templateUrl: './store-profile.component.html',
  styleUrls: ['./store-profile.component.css']
})
export class StoreProfileComponent implements OnInit {

  controller: any = "";

  _arr = new Array();

  __arr = new Array();

  opened: boolean = false;
  constructor(private httpClient: HttpClient, private globalService: GlobalService,
    private spinnerService: SpinnerService, private elementRef: ElementRef, private snackBar: MatSnackBar) {

  }

  ngOnInit(): void {

    let that = this;

    let menuname = localStorage.getItem("loja");

    this.httpClient.get(this.globalService.url + "/mm-menu?loja=" + menuname).subscribe((response) => {

      that.controller = response;
      
      that.spinnerService.displayCoreSpinner(false, that.elementRef);

      var element = document.getElementsByTagName("body")[0];
      element.style.overflowY = "auto";

      var path1: any = document.getElementById("path1");

      if (path1 != null) {

        path1.style.fill = that.controller.titleColor2;

      }

      that.getAvailabilityArray(that);

    });

  }

  getPhoneMask(text: string) {

    return ("" + text).replace(/(\d\d)(\d\d\d\d\d)(\d\d\d\d)/, '($1) $2-$3');

  }

  gotoMenu() {

    let orderType = localStorage.getItem("orderType");

    if (orderType == "INDOOR") {

      window.location.href = "/#/?loja=" + this.controller.storename + "&code=" + localStorage.getItem("tableCode");

    } else {

      window.location.href = "/#/?loja=" + this.controller.storename;

    }

  }

  getAvailabilityArray(that: any) {

    let arr = new Array();

    let date = new Date();

    let json = JSON.parse(that.controller.availabilityJson);
    
    arr.push(json.dom != null && json.dom != undefined ? json.dom : ",");
    arr.push(json.seg != null && json.seg != undefined ? json.seg : ",");
    arr.push(json.ter != null && json.ter != undefined ? json.ter : ",");
    arr.push(json.qua != null && json.qua != undefined ? json.qua : ",");
    arr.push(json.qui != null && json.qui != undefined ? json.qui : ",");
    arr.push(json.sex != null && json.sex != undefined ? json.sex : ",");
    arr.push(json.sab != null && json.sab != undefined ? json.sab : ",");
    
    for (let i = date.getDay(); i < arr.length; i++) {

      that._arr.push(arr[i]);

      switch (i) {
        case 0:
          that.__arr.push("Domingo");
          break;
        case 1:
          that.__arr.push("Segunda-Feira");
          break;
        case 2:
          that.__arr.push("Terça-Feira");
          break;
        case 3:
          that.__arr.push("Quarta-Feira");
          break;
        case 4:
          that.__arr.push("Quinta-Feira");
          break;
        case 5:
          that.__arr.push("Sexta-Feira");
          break;
        case 6:
          that.__arr.push("Sábado");
          break;
      }

    }

    for (let i = 0; i < date.getDay(); i++) {

      that._arr.push(arr[i]);

      switch (i) {
        case 0:
          that.__arr.push("Domingo");
          break;
        case 1:
          that.__arr.push("Segunda-Feira");
          break;
        case 2:
          that.__arr.push("Terça-Feira");
          break;
        case 3:
          that.__arr.push("Quarta-Feira");
          break;
        case 4:
          that.__arr.push("Quinta-Feira");
          break;
        case 5:
          that.__arr.push("Sexta-Feira");
          break;
        case 6:
          that.__arr.push("Sábado");
          break;
      }

    }

    that.getOpened(that, that._arr[0]);
    
  }

  getOpened(that: any, day: any) {

    let date = new Date();
    
    let hourStart: string = day.split(",")[0].split(":")[0];

    hourStart = hourStart.charAt(0) == "0" ? hourStart.charAt(1) : hourStart;

    let minutesStart: string = day.split(",")[0].split(":")[1];

    minutesStart = minutesStart.charAt(0) == "0" ? minutesStart.charAt(1) : minutesStart;
    
    let dateStart = new Date();
    dateStart.setHours(<number><unknown>hourStart);
    dateStart.setMinutes(<number><unknown>minutesStart);


    let hourEnd: string = day.split(",")[1].split(":")[0];

    hourEnd = hourEnd.charAt(0) == "0" ? hourEnd.charAt(1) : hourEnd;

    let minutesEnd: string = day.split(",")[1].split(":")[1];

    minutesEnd = minutesEnd.charAt(0) == "0" ? minutesEnd.charAt(1) : minutesEnd;

    let dateEnd = new Date();
    dateEnd.setHours(<number><unknown>hourEnd);
    dateEnd.setMinutes(<number><unknown>minutesEnd);

    if ((date.getTime() < dateStart.getTime() || date.getTime() > dateEnd.getTime())) {
      
      that.opened = false;

    } else {

      if (that.controller.opened) {

        that.opened = true;

      } else {

        that.opened = false;

      }

    }

  }

}
