import { Component, ElementRef } from '@angular/core';
import { SpinnerService } from './services/spinner.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = '';

  constructor(private router: Router, private spinnerService: SpinnerService, private elementRef: ElementRef) {

    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {

        this.spinnerService.displayCoreSpinner(true, elementRef);

        //this.globalService.setEmptyTemplate(false);

        //this.init();


      }

    })

  }

}
