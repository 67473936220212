import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public emptyTemplate: boolean = false;

  public url: string = "https://olachef.com.br:8443";

  public nodejsUrl: string = "https://olachef.com.br:65001";

  //public url: string = "http://localhost:5000";

  //public nodejsUrl: string = "http://localhost:65001";

  public snackBarDuration: number = 3000;

  constructor() { }

  public getEmptyTemplate() {

    return this.emptyTemplate;

  }

  public setEmptyTemplate(value: boolean) {

    this.emptyTemplate = value;

  }

}
