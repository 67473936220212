<h2 mat-dialog-title>Qual o seu bairro?</h2>
 <mat-dialog-content class="mat-typography" style="z-index:99999999999 !important">
   <mat-selection-list>
     <mat-list-item *ngFor="let item of districtsDeliveryFeeObjects">
       <button class="district-button" [style]="'background:' + menu.secondaryColor" mat-button style="text-align:left;width:100%; background:unset;color:white;border:0" (click)="select(item)">
         {{item.district}}
       </button>
     </mat-list-item>
   </mat-selection-list>
 </mat-dialog-content>
