import { Injectable } from '@angular/core'
import { FormGroup } from '@angular/forms';
import { WeekDay, formatDate, getLocaleDateFormat } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DateService {

  constructor() {

  }

  public toDate(dateStr: any) {

    var parts = dateStr.split("-")
    return new Date(parts[0], parts[1] - 1, parts[2])

  }

  public millisToDate(millis: any) {

    let date: Date = new Date();
    date.setTime(millis);

    return formatDate(date, "dd/MM/yyyy", "en");

  }

  public millisToLongDate(millis: any) {

    let date: Date = new Date();
    date.setTime(millis);

    //return formatDate(date, "ddd. d MMMM yyyy", "en").toLocaleLowerCase();

    let langCode = "pt-BR";

    var weekDay = date.toLocaleString(langCode, { weekday: 'short' });
    weekDay = weekDay.charAt(0).toUpperCase() + weekDay.slice(1);
    var day = date.toLocaleString(langCode, { day: '2-digit' });   // DD
    var month = date.toLocaleString(langCode, { month: 'long' }); // MMM
    var year = date.toLocaleString(langCode, { year: 'numeric' }); // YYYY
    return `${weekDay} ${day} ${month} ${year}`;

    return date.toLocaleTimeString("pt-BR", { hour: '2-digit', minute: '2-digit' });
  }

  public millisToDateObject(millis: any) {

    let date: Date = new Date();
    date.setTime(millis);

    return date;

  }

  public millisToDatetime(millis: any) {

    let date: Date = new Date();
    date.setTime(millis);

    return formatDate(date, "dd/MM/yyyy HH:mm", "en");

  }

  public dateToDatetime(date: Date) {

    return formatDate(date, "dd/MM/yyyy HH:mm", "en");

  }

  public dateToDatetime_as(date: Date) {

    if (date == null || date == undefined) {

      return "00/00/0000";

    }

    return formatDate(date, "dd/MM/yyyy", "en") + " às " + formatDate(date, "HH:mm", "en");;

  }

  public millisToTime(millis: any) {

    let date: Date = new Date();
    date.setTime(millis);

    return formatDate(date, "HH:mm", "en");

  }

  public millisToTime_(millis: any) {

    let date: Date = new Date();
    date.setTime(millis);

    return formatDate(date, "HH:mm", "en");

  }

  public millisToTime__(millis: any) {

    let date: Date = new Date();
    date.setTime(millis);

    return formatDate(date, "m:ss", "en");

  }

  public millisToTimeIFood(millis: any) {

    let date: Date = new Date();
    date.setTime(millis);

    return formatDate(date, "HH:mm", "en");

  }

  public millisToDatetimeIFood(millis: any) {

    let date: Date = new Date();
    date.setTime(millis);

    return formatDate(date, "dd/MM/yyyy HH:mm", "en");

  }

  public dateToString(date: Date) {

    var mm = date.getUTCMonth() + 1;
    var dd = date.getUTCDate();

    return [date.getUTCFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');

  }

  public dateDifferenceInMinutes(dateMiliseconds: Date) {

    let today = new Date();

    let millis = <number><unknown>today.getTime() - <number><unknown>dateMiliseconds.getTime();

    var minutes = Math.floor(millis / 60000)

    return minutes;

  }

  public isSameDate(date1: any, date2: any) {

    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();

  }

}
