
<div id="app">
  
  <div class="view view-main view-init ios-edges " data-url="/">
    <div class="page">

      <!-- navbar -->
      <div id="navbar" class="navbar navbar-cart"
           style="background: #fff; display: none; position: fixed; z-index: 99999999; height: 57px;">

        <div class="navbar-content" style="margin-top: 15px; margin-left: 15px">

          <div class="" style="text-align: left">
            <div style="margin-top: 3px">
              <h4 id="delivery-top" class="nc-normal"
                  [innerHTML]="controller.opened ? 'Loja aberta' : 'Loja fechada'"
                  [style]="'color:' + controller.secondaryColor + ' !important;left: 0;font-weight: 400;' + (!controller.opened ? 'color:#999 !important' : '')" style="margin-top: 20px"></h4>


            </div>
            <div class="right icon-cart" style="position: absolute; right: 65px; top: 27px;" (click)="toggleFilterSection()">
              <i class="fa fa-search" style="position:absolute;left:30px;font-size:14pt;cursor:pointer" [style]="'color:' + controller.primaryColor"></i>
            </div>

          </div>

        </div>
      </div>
      <!-- end navbar -->

      <div id="page-content" class="page-content" (scroll)="onScroll($event)">

        <!--<a href="/#/pedidos">
          <div style="width:35px;height:35px;border-radius:50px; background:#0e0e0e; margin-top:25px; right:15px;top:15px;position: absolute;opacity:0.9">
            <svg style="width: 14px; fill: white; left: 11px; top: 7px; position: relative;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.3-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8V488c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488V24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zM80 352c0 8.8 7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96z" /></svg>
          </div>
        </a>-->

        <img *ngIf="controller.coverPhoto != null && controller.coverPhoto != undefined && controller.coverPhoto.trim() != ''" [src]="controller.coverPhoto"
             [style]="(!controller.opened ? 'filter:grayscale(1);' : '') + 'position: relative; bottom: -25px; z-index: -1; width: 100%; object-fit: cover; height: 150px'" />

        <div *ngIf="controller.coverPhoto == null || controller.coverPhoto == undefined || controller.coverPhoto.trim() == ''"
             [style]="'background:white;position: relative; bottom: -25px; z-index: -1; width: 100%; object-fit: cover; height: 35px'"></div>

        <div class="nc-container">
        <div id="delivery-header" class="title sliding title-heading"
             style="margin-bottom: 15px; padding-top: 25px !important; text-align: left; padding: 15px; border-top-left-radius: 25px; border-top-right-radius: 25px; background: white">
          <div [style]="controller.logo == null || controller.logo == undefined || controller.logo.trim() == '' ? '' : 'display: block; float: left;width:calc(100% - 70px)'">
            <div style="position:relative;top:15px">
              <h4 class="nc-normal" [innerHTML]="controller.title"
                  [style]="'color:' + controller.secondaryColor + ' !important;left: 0;' + (!controller.opened ? 'color: #999 !important' : '')"></h4>
            </div>
            <div style="position: relative; top: 19px">
              <div id="opened" class="nc-micro"
                   [style]="'width:fit-content;display:block;float:left;color:' + controller.secondaryColor + ' !important; margin-top: 8px'">

              </div>
              <span *ngIf="controller.minimumOrder != null && controller.minimumOrder != undefined" style="float:left; margin-top: 9px" class="nc-micro">&nbsp;•&nbsp;Pedido min.&nbsp;<b class="nc-micro-bold" [innerHTML]="controller.minimumOrder"></b></span>
            </div>
          </div>
          <div *ngIf="controller.logo != null && controller.logo != undefined && controller.logo.trim() != ''">
            <a href="/#/perfil">
              <img [style]="!controller.opened ? 'filter:grayscale(1);' : ''" [src]="controller.logo" width="40" height="40" style="border-radius: 50%; float: right">
              <span class="nc-micro-bold" [style]="'display: block;float: right;font-weight: bold;color:' +controller. primaryColor">Perfil da loja</span>
            </a>
          </div>

        </div>
        <div style="width: calc(100% - 15px);float:left;margin-top: 30px;margin-left: 15px; margin-bottom: 30px;cursor:pointer">
          <select id="order-type" (change)="changeOrderType()" style="border-radius:4px;padding:8px;padding-left:5px;padding-right:5px;background:#f2f2f2;-webkit-appearance: auto;-moz-appearance: auto;appearance: auto;color:black;cursor:pointer; float: left" class="nc-micro" [style]="'color:' + controller.titleColor + ' !important'">
            <option setected value="DELIVERY">Entrega</option>
            <option value="TAKEOUT">Retirada</option>
          </select>
          <div style="float: right; margin-right:15px">
            <a href="/#/pedidos" style="float: right">
              <div style="width:35px;height:35px;border-radius:50px; background:#0e0e0e; opacity:0.9">
                <svg style="width: 14px; fill: white; left: 11px; top: 7px; position: relative;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.3-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8V488c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488V24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zM80 352c0 8.8 7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96z" /></svg>
              </div>
            </a>
            <div style="float: right; margin-right:5px" (click)="openAIAgentChat()" [hidden]="controller.accountPlan != 'PREMIUM'">
              <div style="width:35px;height:35px;border-radius:50px; background:#0e0e0e; opacity:0.9">
                <!--<svg style="width: 25px; fill: white; left: 5px; top: 5px; position: relative;" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="#ffffff"><path d="M160-360q-50 0-85-35t-35-85q0-50 35-85t85-35v-80q0-33 23.5-56.5T240-760h120q0-50 35-85t85-35q50 0 85 35t35 85h120q33 0 56.5 23.5T800-680v80q50 0 85 35t35 85q0 50-35 85t-85 35v160q0 33-23.5 56.5T720-120H240q-33 0-56.5-23.5T160-200v-160Zm200-80q25 0 42.5-17.5T420-500q0-25-17.5-42.5T360-560q-25 0-42.5 17.5T300-500q0 25 17.5 42.5T360-440Zm240 0q25 0 42.5-17.5T660-500q0-25-17.5-42.5T600-560q-25 0-42.5 17.5T540-500q0 25 17.5 42.5T600-440ZM320-280h320v-80H320v80Zm-80 80h480v-480H240v480Zm240-240Z" /></svg>-->
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="20pt" height="19pt" viewBox="0 0 368.000000 361.000000"
                     preserveAspectRatio="xMidYMid meet" style="fill: white; left: 4px; top: 4px; position: relative;">

                  <g transform="translate(0.000000,361.000000) scale(0.100000,-0.100000)"
                     fill="#ffffff" stroke="none">
                    <path d="M1780 3293 c-121 -20 -255 -114 -316 -221 -38 -67 -64 -148 -64 -199
l0 -43 -263 0 c-295 0 -316 -4 -402 -68 -37 -27 -59 -55 -85 -107 l-35 -70 -4
-191 -3 -192 -59 -4 c-87 -6 -211 -71 -280 -145 -211 -231 -143 -596 135 -729
38 -19 84 -36 101 -38 16 -3 47 -8 67 -11 l37 -6 3 -347 c3 -334 4 -349 25
-395 12 -26 32 -62 44 -78 33 -46 117 -96 185 -109 42 -8 334 -10 1034 -8
l975 3 55 26 c70 33 116 79 149 149 l26 55 3 352 3 352 47 6 c112 14 191 49
270 120 195 175 203 484 17 670 -78 78 -201 135 -291 135 l-43 0 -3 203 c-3
197 -4 203 -30 253 -37 69 -81 111 -153 145 l-60 29 -267 0 -267 0 -5 33 c-3
17 -7 50 -10 72 -14 103 -130 257 -235 310 -93 46 -204 64 -301 48z m1030
-1708 l0 -945 -945 0 -945 0 0 945 0 945 945 0 945 0 0 -945z" />
                    <path d="M1280 2013 c-162 -85 -149 -336 22 -407 45 -19 136 -21 175 -4 126
55 179 197 118 317 -58 115 -196 156 -315 94z" />
                    <path d="M2220 2013 c-162 -85 -149 -336 22 -407 45 -19 136 -21 175 -4 126
55 179 197 118 317 -58 115 -196 156 -315 94z" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div id="delivery-categories"
             style="z-index:99;position: fixed; width: 100%; background: white; border-bottom: 1px solid #f4f4f4; top: 56px; display: none">
          <ul id="categories-block" class="mobile-category-selector" style="margin-left: -5px">
            <li *ngFor="let category of controller.categories; let i = index"
                (click)="gotoAnchor(category.id , i)"
                data-first-item="true" data-last-item="false"
                class="mobile-category-selector__tab mobile-category-selector__tab--active">
              <a [id]="'category' + i"
                 [style]="i == 0 ? 'width:max-content;color: ' + controller.primaryColor : 'width:max-content;color: ' + controller.titleColor"
                 [innerHTML]="category.name" class="nc-small"></a>
              <div [id]="'category_border' + i"
                   [style]="i == 0 ? 'border-bottom: 2px solid ' + controller.primaryColor : 'border-bottom: 1px solid white'"
                   class="mobile-category-selector__underline"></div>
            </li>

          </ul>
        </div>

        <div id="search-section" [style]="(searchOpened ? 'top: 100px;opacity:1; transition: top 0.4s ease-in;' : 'top: 0px; transition: top 0.4s ease-out;') + 'background:white;position:fixed;border: none; width: calc(100% - 30px); height: 64px; padding-top: 0; flex: 3 1; align-items: center; padding: 16px 16px; border-bottom: 0; margin-top: 0;padding-top:25px;border-bottom:1px solid rgb(165,165,165) !important;padding-bottom:0 !important;visibility:Hidden;z-index:9'">
          <i class="fa fa-search" style="position:absolute;left:30px;font-size:14pt; margin-top:12px" [style]="'color:' + controller.primaryColor"></i>
          <input class="nc-micro" [(ngModel)]="searchText" type="text" placeholder="Buscar no cardápio" style="background-color: #f5f6f7; border-radius: 4px; height: 44px; width: 100%;padding-left:45px; color: #6e6e6e">
          <i (click)="toggleFilterSection()" class="fa fa-times" style="position:absolute;right:30px;font-size:16pt; margin-top:-32px;cursor:pointer" [style]="'color:rgb(14,14,14)'"></i>



        </div>

        <div id="ask-again-title" class="section-title" style="display:none">

          <h3 [style]="'margin-left:15px;color:' + controller.titleColor + ' !important'">
            Peça
            novamente
          </h3>
        </div>
        <div id="ask-again-content"
             style="display: flex; overflow-x: auto; margin-bottom: 25px; margin-left: 15px; display:none">
          <div *ngFor="let items of askAgain; let i = index">
            <div style="border-radius: 4px; float: left; margin-right: 15px; width: 280px; grid-template: 'image' 104px 'info' 1fr; cursor:pointer" matRipple [matRippleCentered]="true" (click)="addToCart(items.items)">

              <div style="max-height: 104px">
                <div style="display: block; float: left">
                  <img [src]="items.items[0].base64Image != null && items.items[0].base64Image != undefined && items.items[0].base64Image.trim() != '' ? items.items[0].base64Image : getBase64Image()"
                       style="width: 100px; height: 75px; border-radius: 4px; object-fit: cover;"
                       alt="">
                </div>
                <div style="display: block; float: left">
                  <div *ngFor="let item of items.items">
                    <span class="nc-micro" style="margin-left: 8px;font-size: 9pt; padding: 2px; border-radius: 4px; background: #f5f6f7" [style]="'color:' + controller.titleColor + ' !important'">{{item.quantity}}</span>
                    <span class="nc-micro-bold" style="margin-left: 4px;font-size: 9pt" [style]="'color:' + controller.titleColor + ' !important'">{{item.food.name}}</span>
                  </div>
                  <div class="nc-small-bold" style="margin-left: 8px; margin-top: 10px; margin-bottom: 10px" [style]="'color:' + controller.titleColor + ' !important'"><h5>{{sum(items.items)}}</h5></div>
                  <div class="nc-small-bold" ><h5 [style]="'color:' + controller.primaryColor + ' !important; margin-left: 8px'">Adicionar à sacola</h5></div>
                </div>
              </div>
              <div>

              </div>
            </div>

          </div>
        </div>

        <div class="section-title"
             *ngIf="controller.top5 != null && controller.top5.length == 5">

          <h3 class="nc-normal-bold" [style]="'margin-left:15px;color:' + controller.titleColor + ' !important'">
            Os
            mais pedidos
          </h3>
        </div>
        <div style="display: flex; width: 100%; overflow-x: auto; overflow-y: hidden; height: 215px"
             *ngIf="controller.top5 != null && controller.top5.length == 5">
          <div *ngFor="let food of controller.top5; let i = index"
               [style]="i == 0 ? 'margin-left: 15px !important' : ''"
               (click)="onclickFood(food)" style="cursor:pointer" >
            <div style="border-radius: 4px; display: grid; float: left; margin-right: 15px; width: 150px; grid-template: 'image' 104px 'info' 1fr" matRipple [matRippleCentered]="true">

              <div style="max-height: 104px">
                <img style="width: 100%; height: 100%; border-radius: 4px; object-fit: cover; float: right"
                     [src]="food.base64Image != null && food.base64Image != undefined && food.base64Image.trim() != '' ? food.base64Image : getBase64Image()" alt="">
              </div>
              <div>

                <div class="row">
                  <div class="col-lg-12" style="width: 100%">
                    <div class="content" style="padding: 15px">

                      <h5 [style]="'color:' + controller.titleColor + ' !important'"
                          [innerHTML]="currencyFormatInstance(food.price)" class="nc-small-bold"></h5>

                      <p>
                        <b [style]="'color:' + controller.titleColor + ' !important'"
                           [innerHTML]="food.name" class="nc-micro-bold"></b>
                      </p>

                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>

        <!-- product home -->
        <div class="product product-home popular-product segments"
             style="padding-top: 0; padding-bottom: 60px">
          <div class="container" *ngFor="let category of controller.categories; let i = index" style="padding:0">
            <a [id]="category.id" style="position: relative; top: -135px"></a>
            <div class="section-title" style="padding:0 16px; margin-bottom:0; margin-top: 30px">

              <h3 [id]="'food_category' + i" [style]="'color:' + controller.titleColor + ' !important'"
                  [innerHTML]="category.name" class="food_category nc-normal-bold"></h3>
            </div>
            <div [id]="'food-' + food.id" class="food-item" *ngFor="let food of category.foods | nameFilter : searchText"
                 style="margin-bottom: 25px; padding:0 16px; padding-top: 15px; padding-bottom:15px;margin-bottom:0"
                 (click)="toggleClass()" [hidden]="!food.active" matRipple [matRippleCentered]="true">
              <div (click)="onclickFood(food)" style="cursor:pointer" >

                <div class="row">
                  <div class="col-lg-9" style="width: 70%">
                    <div class="content">
                      <div class="nc-nano" [style]="'margin-left:4px;opacity:0.6;padding-bottom:2px;padding-top:2px;padding-left:8px;padding-right:8px;border-radius:8px;font-size:7pt;width:fit-content;background:' + controller.primaryColor + ';color:white'" *ngIf="food.bestSeller">
                        <i class="fa fa-fire" style="margin-right:4px"></i>O mais pedido
                      </div>
                      <p class="nc-micro-bold">
                        <b [style]="'color:' + controller.titleColor + ' !important'"
                           [innerHTML]="food.name"></b>
                      </p>
                      <p class="nc-micro" [style]="'color:' + controller.titleColor2 + ' !important'"
                         [innerHTML]="food.description != null ? food.description.length <= 65 ? food.description + '...' : food.description.substring(0, 65) + '...' : ''"></p>
                      <h5 class="nc-small-bold" [style]="'color:' + controller.descriptionColor + ' !important'"
                          [innerHTML]="currencyFormatInstance(food.price)"></h5>
                    </div>
                  </div>
                  <div class="col-lg-3" style="width: 30%">


                    <img [id]="'food-img-' + food.id" *ngIf="food.base64Image != null && food.base64Image != undefined && food.base64Image.trim() != ''"
                         style="width: 100px; height: 75px; border-radius: 4px; object-fit: cover; float: right"
                         [src]="food.base64Image" alt="">
                    <img [id]="'food-img-' + food.id" *ngIf="food.base64Image == null || food.base64Image == undefined || food.base64Image.trim() == ''"
                         style="width: 100px; height: 75px; border-radius: 4px; object-fit: cover; float: right"
                         [src]="getBase64Image()" alt="">
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
          </div>
      </div>
    </div>
  </div>
  <div id="bottom-block" class="bottom-block" style="display: none">

    <div id="bottom-block-content" class="bottom-block-content"
         style="display: block">
      <div style="display: block; float: left; width: 50%">
        <p class="bottom-block-p nc-micro"
           style="margin: 0; text-align: left; font-size: 9pt" [style]="'color:' + controller.titleColor + ' !important'">
          Total <span *ngIf="!freeDeliveryFee">sem a entrega</span><span *ngIf="freeDeliveryFee">com <b>entrega grátis</b></span>
        </p>
        <p class="bottom-block-p"
           style="margin: 0; text-align: left; font-size: 11pt">
          <b><span id="total" class="nc-micro-bold" [style]="'color:' + controller.titleColor + ' !important'"></span> / </b><span id="itens" class="nc-micro" [style]="'color:' + controller.titleColor + ' !important'"></span>
        </p>
      </div>
      <div style="display: block; float: right; width: 50%">
        
        <button mat-button (click)="gotoCart()" [disabled]="loading" class="button-right button waves-effect waves-light" [style]="'background:' + controller.primaryColor + ' !important; height: 40px; width: 100%; margin-top: 0; padding-left: 15px; padding-right: 15px'">
          <span style="color:white">Ver sacola</span>
        </button>
      </div>
    </div>
  </div>
</div>
