import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class StrService {

  constructor() { }

  public normalize(str: string) {

    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  }

}
