<div id="app" style="height: 100%">


  <div class="view view-main view-init ios-edges" data-url="/">

    <div class="page">
      <div class="navbar" [style]="'background: white'">
        <div class="navbar-inner sliding navbar-pages" style=" height: 57px; background: white">

          <div id="back-icon" class="left" (click)="gotoMenu()"
               style="z-index: 999; position: fixed; margin: 15px; padding-left: 10px; padding-right: 20px; border-radius: 50%; background: white; width: 2px">
            <i class="fa fa-angle-left"
               [style]="'color:' + controller.primaryColor + ' !important; font-size: 22pt; height: 100%; padding: 5px; position: relative; right: 10px'">
            </i>
          </div>

          <div class="title sliding title-heading"
               style="position: fixed; width: 100%; margin: 0; margin-top:10px">
            <h5 id="header-text" class="nc-small"
                [style]="'color:' + controller.secondaryColor + ' !important; width: fit-content; margin-left: auto; margin-right: auto;font-size:16px;font-weight:bold'">
              {{controller.title}}
            </h5>
          </div>
          <div id="clear" class="right"
               style="position: absolute; right: 16px"
               >
          </div>
        </div>
      </div>
      <div class="page-content" style="margin-top: 57px">

        <div style="width: calc(100% - 30px);float:left;margin-top: 15px;margin-left: 15px; margin-bottom: 30px">

          <div style="width: 100%">
            <h2 style="display: block;float:left; width: calc(100% - 40px); font-size:18px" [style]="'color:' + controller.titleColor" class="nc-normal" >{{controller.title}}</h2>
            <!--<div style="display: block;float:right"><img [src]="controller.logo" width="40" height="40" style="border-radius: 50%; float: right"></div>-->
          </div>
          <div style="display:inline-flex;width: 100%; margin-top: 15px">
            <h2 class="nc-normal" style="display: block;float:left; width: 100%; font-size:16px;margin-bottom: 0" [style]="'color:' + controller.titleColor">Descrição da loja</h2>

          </div>
          <div style="width: 100%">
            <span id="description" class="nc-micro" [style]="'color:' + controller.titleColor2">{{controller.description}}</span>
          </div>
          <div class="nc-micro" [hidden]="controller.availabilityJson == null || controller.availabilityJson == undefined">
               
            <div style="display:inline-flex;width: 100%; margin-top: 15px">
              <h2 class="nc-normal" style="display: block;float:left; width: 100%; font-size:16px;margin-bottom: 0" [style]="'color:' + controller.titleColor">
              {{opened ? 'Aberto Agora' : 'Fechado agora'}}</h2>

            </div>
            <div style="width: 100%">
              

              <div [style]="'color:' + controller.titleColor2">
                <table>
                  <tr *ngFor="let day of __arr; let i = index" [style]="i == 0 ? 'font-weight: bold' : ''" class="nc-micro">
                    <td style="min-width:125px">{{day}}</td>
                    <td>{{_arr[i] == ',' ? 'Fechado' : _arr[i].split(",")[0] + ' às ' + _arr[i].split(",")[1]}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div [hidden]="controller.address == null || controller.address == undefined || controller.address.trim() == ''">
            <div style="display:inline-flex;width: 100%; margin-top: 15px">
              <h2 class="nc-normal" style="display: block;float:left; width: 100%; font-size:16px;margin-bottom: 0" [style]="'color:' + controller.titleColor">Endereço</h2>

            </div>
            <div style="width: 100%">
              <svg [style]="'width:20px; margin-right: 5px; vertical-align:bottom; color:red: fill:red'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path id="path1" stroke-width="4" cx="13" cy="8" rx="7" ry="7" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>

              <span class="nc-micro" [style]="'color:' + controller.titleColor2">{{controller.address}}</span>
            </div>
          </div>


          <div [hidden]="(controller.phone == null || controller.phone == undefined || controller.phone.trim() == '') && (controller.email == null || controller.email == undefined || controller.email.trim() == '') && (controller.facebookAddress == null || controller.facebookAddress == undefined || controller.facebookAddress.trim() == '') && (controller.instagramAddress == null || controller.instagramAddress == undefined || controller.instagramAddress.trim() == '')">
            <div style="display:inline-flex;width: 100%; margin-top: 15px">
              <h2 class="nc-normal" style="display: block;float:left; width: 100%; font-size:16px;margin-bottom: 0" [style]="'color:' + controller.titleColor">Outras informações</h2>

            </div>
            <div style="width: 100%" class="nc-micro">
              <span [style]="'color:' + controller.titleColor2">{{getPhoneMask(controller.phone)}}</span><br />
              <span [style]="'color:' + controller.titleColor2">{{controller.email}}</span><br />
              <span [style]="'color:' + controller.titleColor2">
                <a [style]="'text-decoration:underline;color:' + controller.titleColor2" [href]="controller.facebookAddress">{{controller.facebookAddress}}</a>
              </span><br />
              <span [style]="'color:' + controller.titleColor2">
                <a [style]="'text-decoration:underline;color:' + controller.titleColor2" [href]="controller.instagramAddress">{{controller.instagramAddress}}</a>
              </span>
            </div>
          </div>

          <div style="width: calc(100% - 30px); text-align:center; position:absolute; bottom: 100px">
            <span class="nc-micro" [style]="'color:' + controller.titleColor2">Todos os preços apresentados no cardápio são definidos pela própria loja.</span><br />
          </div>

        </div>

        </div>

    </div>

  </div>

</div>
