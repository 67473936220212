import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'nameFilter'
})

export class NameFilterPipe implements PipeTransform {
  transform(values: any, key?: string): any[] {
    if (key?.trim() == "") {
      return values;
    }
    
    if (!key) return values;
    key = (key.trim()).toLowerCase();

    return values.filter((val: any) => (val.name).toLowerCase().includes(key));
  }
}
