  <div id="app" style="height: 100%" class="nc-container">

    <div class="view view-main view-init ios-edges" data-url="/">
      <div class="page">
        <div id="navbar" class="navbar" [style]="'height:70px; background: white;opacity:0'">
          <div class="navbar-inner sliding navbar-pages" style="height: 75px">

            <div class="title sliding title-heading"
                 style="position: fixed; width: 100%;margin:0">
              <h5 id="header-text" class="nc-small" [style]="'color:' + controller.secondaryColor + ' !important;width:fit-content;margin-left:auto;margin-right:auto;font-size:16px;font-weight:bold'"
                  [innerHTML]="controller.food.name.length > 17 ? controller.food.name.substring(0, 17) + '...' : controller.food.name"></h5>
            </div>
            
          </div>

        </div>
        <div id="back-icon" class="left" style="cursor:pointer;z-index:999;position: fixed; margin: 15px; padding-left: 20px; padding-right: 20px; border-radius: 50%; background: white; width: 2px">
          <i class="fa fa-angle-left" (click)="gotoMenu()"
             [style]="'color:' + controller.secondaryColor + ' !important; font-size: 23pt; height: 100%; padding: 5px; position: relative; right: 11px'">
          </i>
        </div>
        <div class="page-content" (scroll)="onScroll($event)" style="padding-top: 0">

          <!-- product details -->
          <div class="segments-page" style="min-height: calc(100% + 70px);padding-top: 0">
            <div class="container" style="padding: 0">
              <div class="product-details">
                <div class="contents">


                  <img id="image" [src]="controller.food.base64Image" *ngIf="!controller.food.base64Image.includes('data:image/jpeg;base64,/9j/4TA7RXhpZgAATU0AKgAAAAgACwEOAAIAAADIAAAAkgESAAMA') && controller.food.base64Image != ''"
                       style="position: relative; z-index: -1; width: 100%; object-fit: cover; height: 225px;" />

                  <div *ngIf="!(!controller.food.base64Image.includes('data:image/jpeg;base64,/9j/4TA7RXhpZgAATU0AKgAAAAgACwEOAAIAAADIAAAAkgESAAMA') && controller.food.base64Image != '')" style="height:75px">

                  </div>

                  <div class="desc-short" [style]="controller.food.base64Image.includes('data:image/jpeg;base64,/9j/4TA7RXhpZgAATU0AKgAAAAgACwEOAAIAAADIAAAAkgESAAMA') ? 'margin-top: 90px; padding-left: 16px; padding-right: 16px' : 'margin-top: 15px; padding-left: 16px; padding-right: 16px'">
                    <h4 class="nc-small-bold" [style]="'color:' + controller.titleColor + ' !important;font-weight:bold'"
                        [innerHTML]="controller.food.name"></h4>

                    <p class="nc-micro" [style]="'color:' + controller.titleColor + ' !important'"
                       [innerHTML]="controller.food.bigDescription"></p>
                    <h5 class="nc-small-bold" [style]="'color:' + controller.titleColor2 + ' !important;font-size:14px'"
                        [innerHTML]="getValue(controller.food.price)"></h5>

                  </div>
                  
                </div>
                <div class="row div-992"
                     style="display: block; position: fixed; background: white; width: 100%; bottom: -15px; left: 0; border-top: 1px solid #ccc; padding: 15px;">
                  <div class="div-right">
                  <div class="col-30 no-width" style="float: left; width: calc(30% - 7px)">
                    <div class="contents">
                      <span style="display: block; border-radius: 4px; height: 20px; padding: 10px">
                        <i id="minusIcon" (click)="minus()" class="fa fa-minus"
                           style="display: block; float: left; color: #ccc !important; font-size: 16px; font-weight: bold; margin-left:5px"></i>
                        <input class="nc-micro pd-input input-width" readonly="true" id="quantity" type="number" value="1"
                               style="display: block; float: left; width: 20px; text-align: center; width: 54%; color: #6e6e6e">

                        <i id="plusIcon" (click)="plus()" class="fa fa-plus"
                           [style]="'display: block; float: left; color:' + controller.primaryColor + '!important; font-size: 16px; font-weight: bold; margin-right:5px'"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-70 no-width" style="float: right; width: calc(70% - 7px)">
                    <div class="contents">
                      <button (click)="setFoodToCart(controller.food.id)"
                              class="button-right button waves-effect waves-light nc-micro"
                              [style]="'background:' + controller.primaryColor + ' !important; height: 40px; width: calc(100% - 30px); margin-top: 0; padding-left: 15px; padding-right: 15px'">
                        <span style="float: left">Adicionar</span> <span style="float: right" id="totalAmount"
                                                                         th:text="getValue(controller.food.price)"></span>
                      </button>
                    </div>
                  </div>
                  </div>
                </div>
               
              </div>
            </div>
            <div>
              <div *ngIf="validSizes()"
                   style="display: block; float: left; width: calc(100% - 30px); background: #f2f2f2; padding: 15px; margin-top: 10px">
                <div style="display: block; float: left">
                  <div>
                    <h4>
                      <b class="nc-small-bold" [style]="'color:' + controller.titleColor + ' !important'">
                        Escolha
                        o tamanho {{selectedSize != "" ? " - " + selectedSize : ""}}
                      </b>
                    </h4>
                  </div>
                </div>

              </div>

              <div *ngIf="validSizes()" style="display:block; margin-left:15px; margin-top:5px">

                <div>

                  <div *ngFor="let size of sizes; let i = index">
                
                  <button (click)="selectSize(size)" class="button waves-effect waves-light"
                        [style]="'margin-right:10px;background:' + controller.primaryColor + ' !important; height: 40px; width: calc(100% - 30px); margin-top: 10px; padding-left: 15px; padding-right: 15px; width:unset;float:left'">
                      <span style="float: left" class="nc-small">{{size.sizeLabel}}</span>
                  </button>

                  </div>

                </div>

              </div>

              <div *ngIf="controller.hasFlavors"
                   style="display: block; float: left; width: calc(100% - 30px); background: #f2f2f2; padding: 15px; margin-top: 10px">
                <div style="display: block; float: left">
                  <div>
                    <h4>
                      <b class="nc-small-bold" [style]="'color:' + controller.titleColor + ' !important'">
                        Escolha
                        os sabores
                      </b>
                    </h4>
                  </div>
                  <div [style]="'color:' + controller.titleColor + ' !important'" class="nc-micro">
                    Escolha <span [innerHTML]="controller.food.flavorsQuantity"></span> opções
                  </div>
                </div>

                <div style="display: block; float: right">
                  <div [style]="'background:' + controller.titleColor + ' !important; color: white; padding: 6px 6px 4px; border-radius: 4px'" class="nc-micro">
                    <span id="selectedFlavors">0</span>/<span [innerHTML]="controller.food.flavorsQuantity"></span>
                  </div>
                </div>
              </div>
              <div *ngFor="let flavor of controller.flavors; let i = index" class="align-percentage"
                   style="display: block; float: left; width: calc(100% - 30px); padding: 15px">

                <div [style]="'color:' + controller.titleColor + ';display: block; float: left'">
                  1/<span [innerHTML]="controller.food.flavorsQuantity">0</span>&nbsp;<span [id]="'flavorName' + i"
                                                                                       [innerHTML]="flavor.name"></span> <br> <span [style]="'color:' + controller.titleColor2 + ';display: block; float: left'"
                                                                                                                                    [innerHTML]="'+ ' + getFlavorValue(flavor)"></span>
                </div>
                <div style="display: block; float: right; width: 30%">
                  <span style="display: block; border: 1px solid #ccc; border-radius: 4px; height: 20px; padding: 10px">
                    <i [id]="'minusIcon' + i" class="fa fa-minus"
                       (click)="removeFlavor(i, flavor , flavor.id)"
                       style="display: block; float: left; color: #ccc !important; font-size: 16px; font-weight: bold; width: 25%"></i>
                    <input readonly="true" class="nc-micro"
                           [id]="'flavorQuantity' + i" type="number"
                           value="0"
                           style="display: block; float: left; width: 20px; text-align: center; width: 50%;color:#6e6e6e">

                    <i [id]="'plusIcon' + i" class="fa fa-plus"
                       (click)="addFlavor(i , flavor, flavor.id)"
                       [style]="'display: block; float: left; color:' + controller.primaryColor + '!important; font-size: 16px; font-weight: bold; width: 25%'"></i>
                  </span>
                </div>

              </div>

              <div *ngIf="controller.hasComplements"
                   style="display: block; float: left; width: calc(100% - 30px); background: #f2f2f2; padding: 15px; margin-top: 10px">
                <div style="display: block; float: left">
                  <div style="display: block; float: left">
                    <h4>
                      <b class="nc-small-bold" [style]="'color:' + controller.titleColor">
                        Adicionais
                      </b>
                    </h4>
                  </div>

                </div>

              </div>
              <div *ngFor="let complement of controller.complements; let i = index" class="align-percentage"
                   style="display: block; float: left; width: calc(100% - 30px); padding: 15px">
                <span [id]="'complement_com' + i"
                      [style]="'color:' + controller.titleColor + ';display: block; float: left'"
                      [innerHTML]="complement.name" class="nc-micro"></span> <br>
                <span [style]="'color:' + controller.titleColor + ';display: block; float: left'"
                      [innerHTML]="'+ ' + getValue(complement.price)" class="nc-micro"></span>

                <div style="display: block; float: right; width: 30%; margin-top: -20px">
                  <span style="display: block; border: 1px solid #ccc; border-radius: 4px; height: 20px; padding: 10px; padding-top: 15px">
                    <i [id]="'_minusIcon' + i" class="fa fa-minus"
                       (click)="_minus(i, complement.price, complement.id)"
                       style="display: block; float: left; color: #ccc !important; font-size: 16px; font-weight: bold; width: 25%"></i>
                    <input readonly="true" [id]="'quantity' + i"
                           type="number" value="0" class="nc-micro"
                           style="display: block; float: left; width: 20px; text-align: center; width: 50%;color:#6e6e6e">

                    <i [id]="'_plusIcon' + i" class="fa fa-plus"
                       (click)="_plus(i, complement.price, complement.id)"
                       [style]="'display: block; float: left; color:' + controller.primaryColor + '!important; font-size: 16px; font-weight: bold; width: 25%'"></i>
                  </span>
                </div>
              </div>

              <div *ngIf="controller.hasIngredients"
                   style="display: block; float: left; width: calc(100% - 30px); background: #f2f2f2; padding: 15px; margin-top: 10px; margin-bottom: 15px">
                <div style="display: block; float: left">
                  <div style="display: block; float: left">
                    <h4>
                      <b [style]="'color:' + controller.titleColor" class="nc-small-bold">Ingredientes</b>
                    </h4>
                  </div>

                </div>

                <div style="display: block; float: right">
                  <div id="csDiv" style="display: flex" class="nc-micro">

                    <span (click)="toggleCS(true)" id="csText" class="nc-micro nc-pointer"
                          [style]="'background:white;color:' + controller.titleColor2 + '; padding: 6px 6px 4px; border-top-right-radius:0 !important;border-bottom-right-radius:0px !important;border-top-left-radius:4px !important;border-bottom-left-radius:4px !important'">COM</span>
                    <span (click)="toggleCS(false)" id="csText_sem" class="nc-micro nc-pointer"
                          [style]="'background:' + controller.primaryColor + ';color: white;padding: 6px 6px 4px;border-top-left-radius:0 !important;border-bottom-left-radius:0 !important;border-top-right-radius:4px !important;border-bottom-right-radius:4px !important'">SEM</span>

                  </div>
                </div>
              </div>
              <div class="com_div align-percentage"
                   *ngFor="let ingredient of controller.ingredients; let i = index"
                   style="display: none; float: left; width: calc(100% - 30px); padding: 15px">
                <span [id]="'ingredient_com' + i" class="nc-micro"
                      [style]="'color:' + controller.titleColor + ';display: block; float: left'"
                      [innerHTML]="ingredient.name"></span> <br>
                <span class="nc-micro" [style]="'color:' + controller.titleColor + ';display: block; float: left'"
                      [innerHTML]="'+ ' + getValue(ingredient.price)"></span>

                <div style="display: block; float: right; width: 30%; margin-top: -20px">
                  <span style="display: block; border: 1px solid #ccc; border-radius: 4px; height: 20px; padding: 10px; padding-top: 15px">
                    <i [id]="'_ingredient_minusIcon' + i" class="fa fa-minus"
                       (click)="_ingredient_minus(i, ingredient.price, ingredient.id)"
                       style="display: block; float: left; color: #ccc !important; font-size: 16px; font-weight: bold; width: 25%"></i>
                    <input class="nc-micro" readonly="true" [id]="'ingredient_quantity' + i"
                           type="number" value="0"
                           style="display: block; float: left; width: 20px; text-align: center; width: 50%; color:#6e6e6e">

                    <i [id]="'_ingredient_plusIcon' + i" class="fa fa-plus"
                       (click)="_ingredient_plus(i, ingredient.price, ingredient.id)"
                       [style]="'display: block; float: left; color:' + controller.primaryColor + '!important; font-size: 16px; font-weight: bold; width: 25%'"></i>
                  </span>
                </div>
              </div>
              <div class="sem_div align-percentage"
                   *ngFor="let ingredient of controller.ingredients; let i = index"
                   style="display: block; float: left; width: calc(100% - 30px); padding: 15px">
                <div [id]="'ingredient_sem' + i"
                     [style]="'color:' + controller.titleColor + ';display: block; float: left'"
                     [innerHTML]="ingredient.name"></div>

                <div style="display: block; float: right; width: 30%; height: -10px">
                  <span style="display: block; float: right">
                    <input [id]="'ingredientCheckbox' + i + '_minus'"
                           type="checkbox" class="nc-micro"
                           (change)="_ingredient_sem(i, ingredient.id)" />

                  </span>
                </div>
              </div>


              <div class="obs-100" style="display: block; float: left; padding-bottom: 100px">
                <div style="margin-top:25px;display: block; float: left; width: calc(100% - 30px); padding: 15px;padding-bottom:0">
                  <div style="display: block; float: left">
                    <h4>
                      <b class="nc-small-bold" [style]="'color:' + controller.titleColor">Alguma observação?</b>
                    </h4>
                  </div>
                  <div style="display: block; float: right">
                    <span [style]="'color:' + controller.titleColor" class="nc-micro">
                      <span id="commentQuantity">0</span>/140
                    </span>
                  </div>
                </div>
                <div style="display: block; float: left; width: calc(100% - 30px); padding: 15px;padding-top:0">
                  <textarea id="commentTextarea" class="nc-micro"
                            (keydown)="calcCommentQuantity()" maxlength="140"
                            placeholder="Ex: Tirar a cebola, maionese à parte, ponto da carne etc"
                            style="border: 1px solid #ccc; border-radius: 4px; padding: 15px; width: 100%; height: 70px;color:#6e6e6e"></textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- end product details -->
        </div>
      </div>
    </div>

    <div class="bottom-block">
      <div id="bottom-block-black" class="bottom-block-black"
           (click)="bottomBlockBlack()"></div>
      <div id="bottom-block-content" class="bottom-block-content">
        <p class="bottom-block-p" style="margin-bottom: 25px">O restaurante está fechado no momento. Mas você pode olhar o cardápio à vontade e voltar quando ele estiver aberto.</p>
        <span style="font-weight: bold; display: table; margin-left:auto; margin-right: auto; color: #333" class="bottom-block-a" (click)="bottomBlockA()">Ok</span>
      </div>
    </div>
  </div>
