import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { SpinnerService } from '../services/spinner.service';
import { DateService } from '../services/date.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  controller: any = "";

  orders: any = new Array();

  lastOrder: any = Object();

  currentOrder: any = null;

  dateService: DateService;

  constructor(private httpClient: HttpClient, private globalService: GlobalService,
    private spinnerService: SpinnerService, private elementRef: ElementRef, _dateService: DateService,
    private snackBar: MatSnackBar) {

    this.dateService = _dateService;

  }

  ngOnInit(): void {

    let that = this;

    let menuname = localStorage.getItem("loja");
    let customerId = localStorage.getItem("customerId");

    if (customerId == null || customerId == undefined || customerId == "null") {

      customerId = "0";

    }

    that.spinnerService.displayCoreSpinner(true, that.elementRef);

    let url = this.globalService.url + "/get-orders-by-customer-id-and-order-status?menuname=" + menuname + "&customerId=" + customerId +
      "&orderStatus=ORDER_COMPLETED";

    this.httpClient.get(url).subscribe((response: any) => {

      that.orders = response;



      //document.getElementById("current-order-section")!.style.display = "block";

    });


    this.httpClient.get(this.globalService.url + "/mm-menu?loja=" + menuname).subscribe((response) => {

      that.controller = response;

      that.spinnerService.displayCoreSpinner(false, that.elementRef);

      var element = document.getElementsByTagName("body")[0];
      element.style.overflowY = "auto";

    });

    let order: any = localStorage.getItem("lastOrderCart");

    that.currentOrder = JSON.parse(order);

    //document.getElementById("current-order-section")!.style.display = "none";

    if (order != null && order != undefined) {

      this.httpClient.get(this.globalService.url + "/get-order-by-id-2?orderId=" + that.currentOrder.id).subscribe((response) => {

        let _order: any = response;

        if (_order["orderStatus"] == "ORDER_COMPLETED") {

          that.currentOrder = null;

        } else {



          //that.currentOrder = JSON.parse(order);

        }

      });

    }

  }

  addToCart(items: any) {

    for (let i = 0; i < items.length; i++) {

      items[i].id = items[i].food.id;
      items[i].name = items[i].food.name;
      items[i].base64Image = items[i].food.base64Image;
      items[i].price = items[i].food.price;

      items[i].complements = items[i].options;
      
      if (items[i].flavors == null || items[i].flavors == undefined) {

        items[i].flavors = new Array();

      }

      if (items[i].flavors_ == null || items[i].flavors_ == undefined) {

        items[i].flavors_ = new Array();

      }

      if (items[i].complements == null || items[i].complements == undefined) {

        items[i].complements = new Array();

      }

      if (items[i].ingredients == null || items[i].ingredients == undefined) {

        items[i].ingredients = new Array();

      }

    }

    let obj: any = new Object();
    obj["items"] = items;

    localStorage.setItem("cart-" + localStorage.getItem("storename"), JSON.stringify(obj));
    
    window.location.href = "/#/c?loja=" + this.controller.storename;

  }

  getFood(id: number, that: any) {

    let categories = that.controller.categories;

    for (let category of categories) {

      let foods = category.foods;

      for (let i = 0; i < foods.length; i++) {

        if (foods[i].id == id) {

          return foods[i];
        }

      }

    }

  }

  gotoMenu() {

    let orderType = localStorage.getItem("orderType");

    if (orderType == "INDOOR") {

      window.location.href = "/#/?loja=" + this.controller.storename + "&code=" + localStorage.getItem("tableCode");

    } else {

      window.location.href = "/#/?loja=" + this.controller.storename;

    }

  }

  getForecastTime() {
    
    let deliveryTime = <number><unknown>this.controller.deliveryTime;

    let date = new Date(this.currentOrder.date);
    date = new Date(date.getTime() + (deliveryTime * 60000));

    let date2 = new Date(date.getTime() + (10 * 60000))

    return (date.getHours().toString().length == 1 ? "0" + date.getHours() : date.getHours()) +
      ":" + (date.getMinutes().toString().length == 1 ? "0" + date.getMinutes() : date.getMinutes()) +
      " - " + (date2.getHours().toString().length == 1 ? "0" + date2.getHours() : date2.getHours()) +
      ":" + (date2.getMinutes().toString().length == 1 ? "0" + date2.getMinutes() : date2.getMinutes());

  }

  isTakeout() {

    let orderType = this.currentOrder.orderType;

    return orderType == "TAKEOUT";

  }

  gotoDetails() {

    localStorage.setItem("d-page", "orders");

    window.location.href = "/#/detalhes";

  }

  callWhatsapp() {

    window.location.href = "https://api.whatsapp.com/send?phone=" + "55" + this.controller.whatsapp + "&text='";

  }

}
