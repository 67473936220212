import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class URLService {

  constructor() { }

  public getParameter(url_string: string, parameter: string) {

    var url = new URL(url_string.replace("#/", ""));
    var result = url.searchParams.get(parameter);
    return result;

  }

}
